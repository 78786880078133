import React, { ComponentType } from 'react';
import {
  Home,
  Listings2,
  Contacts,
  Sources,
  SyncResults,
  ContactEvents,
  ContactDetail,
  Brokers,
  Resources,
  Ads,
  Stays,
  Rates,
  BookingRounds,
  PremiumDates,
  Listing,
  Profile,
  Users,
  Permissions,
  Owners,
} from './pages';
import Invoices from "./pages/expenses/Invoices";
import Payments from "./pages/expenses/Payments";
import InvoiceDetail from "./pages/expenses/InvoiceDetail";
import UnitDetail from "./pages/houses/UnitDetail";
import OwnerDetail from "./pages/houses/OwnerDetail";
import BrokerDetails from "./pages/partners/BrokerDetails";
import Companies from "./pages/partners/Companies";
import BookingRoundDetails from "./pages/scheduling/BookingRoundDetails";
import Listings from "./pages/houses/Listings";
import RecordTable from "./components/RecordTable";
import FormSubmissions from "./pages/contacts/FormSubmissions";
import Ambassadors from "./pages/partners/Ambassadors";
import { ILocation, LocationModel } from "./types/models/geo";
import { AmenityModel, DesignModel, UnitModel, IAmenity, IDesign, IUnit, IListing } from "./types/models/housing";
import LedgerDetail from "./pages/expenses/LedgerDetail";
import {
  ConceptModel,
  IExpenseConcept,
  ILedger,
  ILedgerCheckpoint,
  LedgerCheckpointModel,
  LedgerModel, UsagePatternModel
} from "./types/models/accounting";
import { ChannelModel } from "./types/models/channels";
import AccountBalances from "./pages/expenses/AccountBalances";
import MonthlyFinancials from "./pages/expenses/MonthlyFinancials";
import moment from "moment/moment";
import { BookingModel, BookingRoundModel, LodgifyPropertyModel, SpecialStayModel } from "./types/models/lodging";
import {
  AccountModelModel,
  AlertModel,
  IAccountModel,
  ITransactionModel,
  NotificationModel,
  TransactionModelModel,
  ListingModel, PromptModel, CompletionChatModel, LocaleModel,
} from "./types/models";
import RecordCalendarView from "./components/RecordCalendarView";

type Route = {
  path: string;
  name: string;
  exact: boolean;
  page: ComponentType;
  icon?: ComponentType;
  forMenu: boolean;
  forDropdown?: boolean;
  groupName?: string;
  subGroupName?: string;
}

export const routes: Route[] = [
  { path: "/", name: "Home", exact: true, page: Home, forMenu: false },

  /* Houses */
  // { path: "/listings2", name: "Listings2", exact: false, page: Listings2, forMenu: false },
  { path: "/listings", name: "Listings", exact: false, page: Listings, forMenu: true, groupName: 'Houses' },
  { path: "/listings/:listing_id", name: "Listing", exact: false, page: Listing, forMenu: false },
  { path: "/listings2", name: "Listings2", exact: false, page: () => <RecordTable<any> fullpage recordModel={ListingModel} />, forMenu: false },
  // { path: "/listings/:listing_id/expenses", name: "Listing Expenses", exact: false, page: Expenses, forMenu: false },
  { path: "/owners", name: "Owners", exact: false, page: Owners, forMenu: true, groupName: 'Houses' },
  { path: "/owners/:owner_id", name: "Owner", exact: false, page: OwnerDetail, forMenu: false },
  // { path: "/amenities", name: "Amenities", exact: false, page: Amenities, forMenu: true, groupName: 'Houses', subGroupName: 'Housing' },

  { path: "/amenities", name: "Amenities", exact: false, page: () => <RecordTable<IAmenity> fullpage recordModel={AmenityModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Housing' },
  { path: "/units", name: "Units", exact: false, page: () => <RecordTable<IUnit> fullpage recordModel={UnitModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Housing' },
  { path: "/units/:unit_id", name: "Units", exact: true, page: UnitDetail, forMenu: false },
  { path: "/designs", name: "Designs", exact: false, page: () => <RecordTable<IDesign> fullpage recordModel={DesignModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Housing' },
  // { path: "/developments", name: "Developments", exact: false, page: Designs, forMenu: true, groupName: 'Houses', subGroupName: 'Housing' },
  { path: "/locations", name: "Locations", exact: false, page: () => <RecordTable<ILocation> fullpage recordModel={LocationModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Housing' },
  // { path: "/capexes", name: "Capital Expenditures", exact: false, page: () => <RecordTable<ICapex> recordModel={CapexModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Housing' },
  // { path: "/opexes", name: "Operating Expenses", exact: false, page: () => <RecordTable<IOpex> recordModel={OpexModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Housing' },
  // { path: "/developers", name: "Developers", exact: false, page: Designs, forMenu: true, groupName: 'Houses', subGroupName: 'Housing' },

  /* Expenses */
  { path: "/monthly-financials", name: "Monthly Financials", exact: false, page: () => <RecordTable<ILedgerCheckpoint> fullpage /*firstFetchQueryAppend={{ month: moment().month()+1, year: moment().year() }}*/ recordModel={LedgerCheckpointModel} />, forMenu: true, groupName: 'Houses' },
  { path: "/monthly-financials/:id", name: "Monthly Financials", exact: false, page: MonthlyFinancials, forMenu: false, groupName: 'Houses' },
  { path: "/account-balances", name: "Account Balances", exact: false, page: AccountBalances, forMenu: true, groupName: 'Records', subGroupName: 'Accounting' },
  { path: "/invoices", name: "Invoices", exact: false, page: Invoices, forMenu: true, groupName: 'Records', subGroupName: 'Accounting' },
  { path: "/expense-concepts", name: "Expense Concepts", exact: false, page: () => <RecordTable<IExpenseConcept> recordModel={ConceptModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Accounting' },
  { path: "/invoices/:invoice_id", name: "Invoice Lines", exact: false, page: InvoiceDetail, forMenu: false },
  // { path: "/payments", name: "Payments", exact: false, page: Payments, forMenu: true, groupName: 'Records', subGroupName: 'Expenses' },
  { path: "/ledgers", name: "Ledgers", exact: false,  page: () => <RecordTable<ILedger> fullpage recordModel={LedgerModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Accounting' },
  { path: "/ledgers/:ledger_id", name: "Ledger Transactions", exact: false,  page: LedgerDetail, forMenu: false },
  // { path: "/account-models", name: "Account Models", exact: false,  page: () => <RecordTable<IAccountModel> fullpage recordModel={AccountModelModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Accounting' },
  { path: "/transaction-models", name: "Transaction Models", exact: false,  page: () => <RecordTable<ITransactionModel> fullpage size="middle" recordModel={TransactionModelModel} />, forMenu: true, groupName: 'Records', subGroupName: 'Accounting' },


  /* Scheduling */
  { path: "/booking-rounds", name: "Booking Rounds", exact: false, page: () => <RecordTable recordModel={BookingRoundModel} size="middle" fullpage /> , forMenu: true, groupName: 'Houses' },
  { path: "/booking-rounds/:round_id", name: "Booking Round", exact: false, page: BookingRoundDetails, forMenu: false },
  { path: "/bookings", name: "Bookings", exact: false, page: () => <RecordCalendarView recordModel={BookingModel} />, forMenu: true, groupName: 'Houses', },
  { path: "/stays", name: "Stays", exact: false, page: Stays, forMenu: true, groupName: 'Records', subGroupName: 'Lodging', },
  { path: "/rental-prices", name: "Rental Prices", exact: false, page: Rates, forMenu: true, groupName: 'Records', subGroupName: 'Lodging', },
  { path: "/booking-roundsss", name: "Booking Rounds", exact: false, page: BookingRounds, forMenu: false, groupName: 'Houses' },
  { path: "/special-stays", name: "Special Stays", exact: false, page: () => <RecordTable recordModel={SpecialStayModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Lodging' },
  { path: "/usage-patterns", name: "Usage Patterns", exact: false, page: () => <RecordTable recordModel={UsagePatternModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Lodging' },
  { path: "/lodgify-properties", name: "Lodgify Properties", exact: false, page: () => <RecordTable recordModel={LodgifyPropertyModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Lodging' },
  // { path: "/premium-dates", name: "Premium Dates", exact: false, page: PremiumDates, forMenu: true, groupName: 'Houses', subGroupName: 'Scheduling' },

  /* CRM */
  { path: "/crm/contacts", name: "Contacts", exact: false, page: Contacts, forMenu: true, groupName: 'Contacts' },
  { path: "/crm/form-submissions", name: "Form Submissions", exact: false, page: FormSubmissions, forMenu: true, groupName: 'Contacts' },
  { path: "/crm/contacts/:c_id", name: "Contact Detail", exact: false, page: ContactDetail, forMenu: false },
  { path: "/crm/events", name: "Contact Events", exact: false, page: ContactEvents, forMenu: true, groupName: 'Contacts' },

  /* Partners */
  { path: "/brokers", name: "Brokers", exact: false, page: Brokers, forMenu: true, groupName: 'Partners' },
  { path: "/ambassadors", name: "Ambassadors", exact: false, page: Ambassadors, forMenu: true, groupName: 'Partners' },
  { path: "/brokers/:broker_id", name: "Broker Details", exact: false, page: BrokerDetails, forMenu: false },
  { path: "/companies", name: "Companies", exact: false, page: Companies, forMenu: true, groupName: 'Partners' },
  // { path: "/resources", name: "Resources", exact: false, page: Resources, forMenu: true, groupName: 'Partners' },

  /* Marketing */
  { path: "/marketing/ads", name: "Ads", exact: false, page: Ads, forMenu: true, groupName: 'Marketing' },

  /* Comms */
  { path: "/channels", name: "Channels", exact: false, page: () => <RecordTable recordModel={ChannelModel} />, forMenu: false, groupName: 'Comms' },
  { path: "/alerts", name: "Alerts", exact: false, page: () => <RecordTable recordModel={AlertModel} fullpage />, forMenu: false, groupName: 'Comms' },
  { path: "/notifications", name: "Notifications", exact: false, page: () => <RecordTable allowRowSelect recordModel={NotificationModel} fullpage />, forMenu: false, groupName: 'Comms' },

  /* Misc */
  { path: "/prompts", name: "Prompts", exact: false, page: () => <RecordTable allowRowSelect recordModel={PromptModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Misc' },
  { path: "/completion-chats", name: "Completion Chats", exact: false, page: () => <RecordTable recordModel={CompletionChatModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Misc' },
  { path: "/locales", name: "Locales", exact: false, page: () => <RecordTable recordModel={LocaleModel} fullpage />, forMenu: true, groupName: 'Records', subGroupName: 'Misc' },

  /* Users */
  { path: "/profile", name: "Profile", exact: false, page: Profile, forMenu: false },
  { path: "/users", name: "Users", exact: false, page: Users, forMenu: false, forDropdown: true },
  { path: "/permissions", name: "Permissions", exact: false, page: Permissions, forMenu: false },

  /* Date Sources */
  { path: "/sources", name: "Data Pipes", exact: false, page: Sources, forMenu: false, forDropdown: true },
  { path: "/sources/:source_id/syncs", name: "Data Sources Sync Results", exact: false, page: SyncResults, forMenu: false },
];
