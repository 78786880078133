import React, { useEffect, useState } from 'react';
import './App.less';
import { add, groupBy, keys, values } from 'ramda';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { Button, Popover, Layout, Menu, Divider } from 'antd';
import { UserOutlined, BellOutlined } from '@ant-design/icons';
import { routes } from "./routes";
import useAuth from "./hooks/useAuth";
import LoginForm from "./components/LoginForm";

const { Header, Content } = Layout;

// const UserContext = React.createContext({ user: null, fetchUser: () => {} });

function App() {
  const { pathname } = useLocation();
  const { user, logout } = useAuth();
  const [menuHoverable, setMenuHoverable] = useState(false);
  const menuRoutes = routes.filter(r => r.forMenu);
  const simpleRoutes = menuRoutes.filter(r => r.groupName === undefined);
  const groupedRoutes = groupBy(
    (route: any) => route.groupName || '',
    menuRoutes.filter(r => r.groupName !== undefined)
  );

  if (!user) {
    return <div style={{ background: '#f6f6f6', height: '100vh', alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
      <LoginForm />
      <div style={{ width: 100, height: 75 }} />
    </div>
  }

  return (
      <div className="App">
        <Layout style={{ height: '100vh' }}>
          <Header className="header">
            <Link to="/">
            <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="24.68" height="28.349" viewBox="0 0 24.68 28.349">
              <path id="Path_131" data-name="Path 131" d="M24.566,29.508,19.372,16.575Q16.39,9.15,13.412,1.724a.314.314,0,0,0-.349-.234c-.567.012-1.134.013-1.7,0A.327.327,0,0,0,11,1.74Q5.679,15.334.349,28.924c-.114.291-.225.584-.349.909H.284c1.107,0,2.214,0,3.321,0a.331.331,0,0,0,.364-.253q1.487-3.814,2.987-7.622l1.186-3.019c.1.083.171.136.237.193Q14.5,24.4,20.619,29.658a.755.755,0,0,0,.447.167c1.116.014,2.231.008,3.347.007.083,0,.166-.01.267-.016-.047-.127-.078-.218-.114-.308M17.8,22.639l-1.746-1.5Q12.878,18.4,9.7,15.667a.326.326,0,0,1-.109-.442q1.289-3.263,2.561-6.533c.031-.079.069-.155.14-.312L17.876,22.6l-.077.043" transform="translate(0 -1.487)" fill="#fff"/>
            </svg>
            </Link>

            <Menu
              mode="horizontal"
              triggerSubMenuAction={menuHoverable ? 'hover' : 'click'}
              onOpenChange={(op: any) => setMenuHoverable(op.length > 0)}
              style={{ height: '100%', borderRight: 0, flex: 1, background: 'transparent', borderBottom: 'none', alignItems: 'center' }}
            >
              {keys(groupedRoutes).map((groupName: any) => {
                let addedSubs: string[] = [];
                return(
                  <Menu.SubMenu
                    title={(
                      <>
                        {groupName}
                        <svg className="nav-icon arrow-down-icon" style={{ maxHeight: 4, maxWidth: 10}} data-name="Layer 1" viewBox="0 0 24 13" xmlns="http://www.w3.org/2000/svg">
                          <path
                            transform="translate(0)"
                            fill="#2b3443"
                            d="m21.47 0.41l-9.47 9.02-9.47-9a1.5 1.5 0 1 0 -2.06 2.16l10.5 10a1.51 1.51 0 0 0 0.44 0.28 1.43 1.43 0 0 0 1 0 1.52 1.52 0 0 0 0.59 -0.26l10.5-10a1.5 1.5 0 1 0 -2.03 -2.2"
                          />
                        </svg>
                      </>
                    )}
                  >
                    {groupedRoutes[groupName].filter(r => r.subGroupName === undefined).map((route: any) => (
                      <Menu.Item key={route.path}>
                        <Link to={route.path}>{route.name}</Link>
                      </Menu.Item>
                    ))}

                    {groupedRoutes[groupName].filter(r => r.subGroupName !== undefined).map((route: any) => {
                      const subGroupedRoutes = groupBy(
                        (route: any) => route.subGroupName || '',
                        menuRoutes.filter(r => r.subGroupName !== undefined)
                      );
                      return keys(subGroupedRoutes).map((subGroupName: any) => {
                        if (addedSubs.includes(subGroupName)) {
                          return null;
                        }
                        addedSubs.push(subGroupName);
                        return (
                          <Menu.SubMenu title={subGroupName}>
                            {subGroupedRoutes[subGroupName].map((route: any) => (
                              <Menu.Item key={route.path}>
                                <Link to={route.path}>{route.name}</Link>
                              </Menu.Item>
                            ))}
                          </Menu.SubMenu>
                        );
                      });
                    })}
                  </Menu.SubMenu>
                )
              })}

              {simpleRoutes.map(route => (
                  <Menu.Item key={route.path} icon={/*<route.icon />*/ null}>
                    <Link to={route.path}>{route.name}</Link>
                  </Menu.Item>
                )
              )}
            </Menu>

            <div style={{ display: 'flex', justifyContent: 'flex-end', height: '100%', alignItems: 'center' }}>
              <Popover
                placement="bottom"
                title=""
                trigger="click"
                content={(
                  <div className="pt-md pb-md fw-500" style={{ opacity: 0.75, maxWidth: 250 }}>
                    You don't have any notifications
                  </div>
                )}
              >
                <Button type="text"><BellOutlined color="white" /></Button>
              </Popover>
              <Divider type="vertical" style={{ height: '50%', borderColor: '#ab8c6a' }} />
              <Popover
                placement="bottomRight"
                title={user?.email}
                content={(
                  <>
                    <div className="user-link">
                      <Link to="/profile">Account</Link>
                    </div>
                    {routes.filter(route => !!route.forDropdown).map(route => (
                      <div className="user-link">
                        <Link to={route.path}>{route.name}</Link>
                      </div>
                    ))}
                    <div className="user-link">
                      <a onClick={logout}>Logout</a>
                    </div>
                  </>
                )}
                trigger="click"
              >
                <Button type="text" style={{ marginRight: 12 }}>
                  <UserOutlined />
                  {user?.email}
                </Button>
              </Popover>
            </div>
          </Header>
          <Layout>
            {/*
            <Sider width={200} className="site-layout-background">
            </Sider>
            */}
            <Layout className="contento" id="content">

              <Content
                className="site-layout-background"
                style={{
                  // padding: 24,
                  overflow: 'visible',
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
  );
}

export default App;
