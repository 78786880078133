import styled from 'styled-components';
import { IBooking } from "../../types/models";
import { Popover } from "antd";
import { SmileTwoTone } from "@ant-design/icons";
import { Moment } from "moment";

const Container = styled.div<any>`
  position: absolute;
  width: calc(100% - ${({ checkin, checkout }) => (checkin || checkout) ? '12px' : '0px'});
  top: 30%;
  left: ${({ checkin }) => checkin ? '12px' : '0px'};
  right: ${({ checkout }) => checkout ? '12px' : '0px'};

  .preview {
    display: flex;
    position: relative;
    padding-left: 12px;
    align-items: center;
    font-size: 90%;
    height: 28px;

    border-top-left-radius: ${({ checkin }) => checkin ? '24px' : 0};
    border-bottom-left-radius: ${({ checkin }) => checkin ? '24px' : 0};
    border-top-right-radius: ${({ checkout }) => checkout ? '24px' : 0};
    border-bottom-right-radius: ${({ checkout }) => checkout ? '24px' : 0};

    text-align: start;
    cursor: pointer;
    background-color: ${({ color }) => color};
    box-shadow: 0 1px 1px rgba(0, 0, 0, .33);
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 250%;
    position: absolute;
    z-index: 10;
    gap: 6px;
  }
`;

interface BookingPreviewProps {
  booking: IBooking;
  date: Moment;
}

function BookingPreview(props: BookingPreviewProps) {
  const { booking } = props;
  const dateStr = props.date.format('YYYY-MM-DD');
  const isCheckin = booking.start_date === dateStr;
  const isCheckout = booking.end_date === dateStr;

  const getColor = () => {
    let color = "#f4e6d7";
    if (booking.for_rent || !booking.unit_owner) {
      return "#eae7e7";
    }
    return color;
  }

  return (
    <Container color={getColor()} checkin={isCheckin} checkout={isCheckout}>
      <Popover
        trigger="click"
        placement="left"
        content={(<div className="booking-card">
          <div>{booking.unit_owner?.owner.first_name}'s Stay #{booking.id}</div>
          <div>check-in: {booking.start_date}, check-out: {booking.end_date}</div>
        </div>)}
      >
        {isCheckin ? (
          <div className="preview">
            {!!booking.unit_owner ? (
              <div className="content">
                <div>{booking.unit_owner?.owner.first_name}'s Stay #{booking.id}</div>
                <div className="booking-icons">{booking.is_preferred ? <SmileTwoTone /> : null}</div>
              </div>
            ) : (
              <div>Empty fraction</div>
            )}
          </div>
        ) : (
          <div className="preview" />
        )}
      </Popover>
    </Container>
  )
}

export default BookingPreview;
