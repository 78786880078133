import styled from 'styled-components';
import { ConceptModel, ILedgerCheckpoint, WalletTransactionModel } from "../../types/models/accounting";
import { OwnerType } from "../../types/coreModels";
import { Form, Input, InputNumber, message, PageHeader, Popconfirm, Table } from "antd";
import React, { useMemo, useRef, useState } from "react";
import FormDrawer from "../FormDrawer";
import RecordSelect from "../fields/RecordSelect";
import { EditableCell, EditableRow } from "../editable";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useRecordFiles from "../useRecordFiles";
import useQuery from "../../hooks/useQuery";
import { ancanaApi } from "../../api/ancanaApi";
import { CheckpointAccountBalance } from "../../pages/expenses/MonthlyFinancials";
import moment from "moment/moment";
import { DeleteOutlined, FolderAddOutlined, PlusOutlined } from "@ant-design/icons";

const Container = styled.div`
`;

const getColumns = (disabled: boolean, deleteRecord: any, patchRecord: any, onExpand: any, currencySymbol = '$', fractionCount = 1) => {
  const allColsSettings = { ellipsis: true }
  return [
    { title: 'Code', dataIndex: ['concept', 'code'], width: 70 },
    { title: 'Name', dataIndex: 'name', sorter: true, key: 'id' },
    {
      title: 'Rent Deposit',
      key: 'amount',
      render: (r: any) => <div className="numbers-cont">{currencySymbol} {Number(r.entry_set[0].amount).toLocaleString()}</div>,
      // width: 175,
      // editable: true,
    },
    {
      title: 'Service Fee',
      key: 'amount',
      render: (r: any) => <div className="numbers-cont">{currencySymbol} {Number(r.entry_set[2]?.amount).toLocaleString()}</div>,
      //width: 175,
    },
    {
      title: 'Owner Earnings',
      key: 'amount',
      render: (r: any) => <div className="numbers-cont">{currencySymbol} {Number(r.entry_set[1].amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>,
      //width: 175,
    },
    // { title: 'Recurrent', dataIndex: 'name', sorter: true, key: 'id', editable: true },
    {
      title: 'Balance',
      render: (line: any) => <div className="numbers-cont">${line.balance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>,
      //width: 175
    },
    {
      title: 'Actions', width: 140, render: (line: any) => (
        <div className="row-actions">
          <a onClick={() => onExpand(line.id)}><FolderAddOutlined /> Files</a>
          <Popconfirm
            title={`Delete this line?`}
            onConfirm={() => deleteRecord(line.id)}
            okText="Yes"
            disabled={disabled}
          >
            <a style={{ color: disabled ? 'lightgray' : '' }}><DeleteOutlined /> Delete</a>
          </Popconfirm>
        </div>
      )
    },
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

interface OwnerWalletProps {
  checkpoint: ILedgerCheckpoint;
  unitOwnership: OwnerType;
  balance?: CheckpointAccountBalance;
  disabled?: boolean;
}

function OwnerWallet(props: OwnerWalletProps) {
  const { checkpoint, unitOwnership, disabled = false } = props;
  const { query } = useQuery();
  const {
    // pagination,
    handleChange,
    isLoading,
    createRecord,
    deleteRecord,
    patchRecord,
    recordList: lines,
    firstFetch
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'ledger-transactions',
    extraQueryAppend: `&ledger=${checkpoint.ledger.id}&month=${checkpoint.month}&year=${checkpoint.year}&expand=concept&account_name=Owner+Cash&ordering=id&object_content=${unitOwnership.id}_258`,
    apiService: 'admin',
    pageSize: '100',
    initialAutoFetch: true
  });
  const formDrawerRef = useRef<any>();
  const [currentAmount, setCurrentAmount] = useState<number>(0);
  const feeAmount = useMemo(() => {
    return Number((currentAmount * (Number(checkpoint.admin_fee)/100) * 1.16).toFixed(2));
  }, [currentAmount]);
  const ownerAmount = useMemo(() => {
    return Number(currentAmount - feeAmount).toFixed(2)
  }, [feeAmount]);
  const { tableExpandableConfig, onExpand, rowKeysMapper } = useRecordFiles('ledger-transactions');

  const handleCreate = (data: { concept: string, name: string, amount: number }) => {
    const payload = unitOwnership.cash_account ? {
      transaction: {
        ledger: checkpoint.ledger.id,
        name: data.name,
        effective_at: `${checkpoint.year}-${checkpoint.month}-01`,
        status: 'posted',
        posted_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        concept: data.concept
      },
      entries: [
        {
          amount: data.amount.toString(),
          account: checkpoint.ledger.unit_cash_account,
          direction: "debit"
        },
        {
          amount: ownerAmount.toString(),
          account: unitOwnership.cash_account.id,
          direction: "credit",
        },
        {
          amount: feeAmount.toString(),
          account: checkpoint.ledger.unit_revenue_account,
          direction: "credit",
        }
      ]
    } : null;
    ancanaApi.post('ledger-transactions/', payload).then(res => {
      firstFetch();
    });
  }

  const handleSave = (row: any) => {
    message.error(`Edit disabled for house expenses`);
  };

  const columns = getColumns(disabled, deleteRecord, patchRecord, onExpand, '$', unitOwnership.fraction_numbers.length).map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      }),
    };
  });

  return (
    <Container>
      <div>
        <PageHeader
          title={`STR Entries`}
          subTitle={`${lines.length} records`}
          extra={[
            <FormDrawer
              ref={formDrawerRef}
              actionName="Add Row"
              recordName=""
              handleRecordAction={handleCreate}
              btnIcon={<PlusOutlined />}
              disabled={disabled}
            >
              <RecordSelect
                formName="concept"
                recordModel={ConceptModel}
                defaultValue={"35"}
                extraFilterQuery={`&type=reverse_deposits`}
              />
              <Form.Item label="Name" name="name" initialValue="STR Earnings">
                <Input />
              </Form.Item>
              <Form.Item label="total amount" name="amount" initialValue={currentAmount}>
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  onChange={(val: number) => {
                    setCurrentAmount(val);
                  }}
                />
              </Form.Item>

              <label>Admin Fee <small>({checkpoint.admin_fee}% + 16% VAT)</small></label>
              <InputNumber
                disabled
                value={feeAmount}
                style={{ width: '100%' }}
                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                className="mb-ml"
              />

              <label>Owner Amount</label>
              <InputNumber
                disabled
                value={ownerAmount}
                style={{ width: '100%' }}
                formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </FormDrawer>
          ]}
          className="mb-md"
        />
        <Table
          loading={isLoading}
          columns={columns}
          components={{ body: { row: EditableRow, cell: EditableCell } }}
          rowClassName={() => 'editable-row'}
          dataSource={lines.map((line, idx) => {
            const bal = lines.slice(0, idx+1).reduce(
              (acc, t) => Number(t.entry_set[1].amount) + acc,
              props.balance?.initial.pending_balance.amount || 0
            );
            return { ...line, balance: Number(bal) };
          }).map(rowKeysMapper)}
          pagination={false}
          // loading={isLoading}
          sticky={{ offsetHeader: 93 }}
          onChange={handleChange}
          // scroll={{ y: elementRef.current?.clientHeight - 150 }}
          size="small"
          expandable={tableExpandableConfig}
        />
      </div>
    </Container>
  )
}

export default OwnerWallet;
