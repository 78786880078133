import { RecordModel } from "../core";
import RecordSelect from "../../components/fields/RecordSelect";
import React from "react";
import { IContentType } from "./generics";
import { Tag } from "antd";

export interface IPermission {
  id: number,
  name: string,
  codename: string,
  content_type: IContentType,
}

export const PermissionModel: RecordModel<IPermission> = {
  name: "Permission",
  path: "permissions",
  selectQuery: "&page_size=1000",
  tableQuery: "",
  toString: (r: IPermission): string => r.name,
}

export interface IGroup {
  id: number;
  name: string;
  permissions?: IPermission[];
}

export const GroupModel: RecordModel<IGroup> = {
  name: "Permission Group",
  namePlural: "Permission Groups",
  path: "groups",
  selectQuery: "",
  tableQuery: "&expand=permissions",
  toString: r => r.name,
  customTableMappings: {
    permissions: { render: r => (
      <div style={{ display: 'flex', flexWrap: "wrap", gap: 6 }}>
        {r.permissions?.map((p: any) => <Tag>{p.name}</Tag>)}
      </div>
    )},
  },
  customCreateFields: [
    {
      name: "permissions",
      component: (props: any) => (
        <RecordSelect
          required={false}
          label="Permissions"
          formName="permissions"
          mode="multiple"
          recordModel={PermissionModel}
        />
      )
    },
  ]
}

export interface IUser {
  id: number;
  email: string;
  first_name?: string | null;
  last_name?: string | null;
  full_name?: string | null;
  date_joined?: string;
  is_superuser?: boolean;
  groups: IGroup[];
  stripe_accounts?: number[] | {
    id: number;
    stripe_id: string;
    delinquent: boolean;
    livemode: boolean;
    speiaccount: number
  }[];
}

export const UserModel: RecordModel<IUser> = {
  name: "User",
  path: "users",
  selectQuery: "&fields=id,email,full_name",
  tableQuery: "&expand=owner.groups,stripe_accounts",
  toString(r: IUser): string {
    return `${r.full_name} (${r.email})`;
  },
  // customFormMapper: r => ({ ...r, groups: r.groups?.map(g => g.id) }),
  customCreateFields: [
    { name: "email", component: null },
    { name: "groups",
      component: (props: any) => <RecordSelect required={false} label="Groups" formName="groups" mode="multiple"
                                               recordModel={GroupModel} />
    },
  ],
  customTableMappings: {
    email: { omit: true },
    groups: { omit: true },
  }
}
