import { Identifiable, RecordModel } from "../core";
import { components } from "../schema";
import { Image } from "antd";
import { OwnerType } from "../coreModels";
import { LocationModel } from "./geo";

export type IUnit = components['schemas']['Unit'];
export type IDesign = components['schemas']['Design'];
export type IAmenity = {
  id: number;
  name: string;
  code: string;
  sort_index: number;
  icon: string;
};

export const DesignModel: RecordModel<IDesign> = {
  name: "Design",
  path: "housing-designs",
  selectQuery: "&fields=id,name",
  tableQuery: "",
  toString(r): string {
    return r.name ? r.name : r.toString();
  }
}

export const UnitModel: RecordModel<IUnit> = {
  name: "Unit",
  path: "housing-units",
  selectQuery: "&fields=id,name",
  // selectQuery: "&fields=id,name,condo.name,base_house_id&expand=condo",
  tableQuery: "",
  toString(r): string {
    return r.name;
  },
  hasImageGalleries: true,
  customTableMappings: {
    design: { omit: true },
    lat: { omit: true },
    lng: { omit: true },
    fractions_taken: { omit: true },
    unit_ownerships: { omit: true },
    unitopex_set: { omit: true },
    unitcapex_set: { omit: true },
    zip_code: { omit: true },
  },
  customCreateFields: [
    { name: 'price', component: null }, // we skip setting the price on create
    { name: 'currency_iso', component: null }, // we skip this as well
  ]
}

export interface IListing extends Identifiable {
  name: string;
  slug: string;
  lat: string;
  lng: string;
  type: 'unit' | 'design';
  published: boolean;
  sort_index: number;
  amenities: any[];
  images: string[];
}

export const ListingModel: RecordModel<IListing> = {
  name: "Listing",
  path: "listings",
  selectQuery: "&fields=id,name,slug",
  tableQuery: "&expand=location,unit&fields=*,location.name,location.id,unit.id,unit.name&omit=files,characteristics,design,images",
  toString: (record) => record.name,
  primaryKeyName: 'slug',
  customTableMappings: {
    price: { path: ['price', 'amount'] },
    amenities: { omit: true },
    files: { omit: true },
    characteristics: { omit: true },
    design: { omit: true },
    images: { omit: true },
    lat: { omit: true },
    lng: { omit: true },
    address: { omit: true },
    cover_image: { omit: true },
    description: { omit: true },
    usage_modes: { omit: true },
    benefits: { omit: true },
  },
  tableFilters: [
    { selectModel: LocationModel },
  ]
}

export const AmenityModel: RecordModel<IAmenity> = {
  name: "Amenity",
  namePlural: "Amenities",
  path: "amenities",
  selectQuery: "",
  tableQuery: "",
  toString(r): string {
    return r.name || r.code;
  },
  toDescriptor: r => (
    <>
      <img src={r.icon} width={25} style={{ marginRight: 4 }} /><br/>
      <small>{r.name || r.code}</small>
    </>
  ),
  tableFilters: [
    { selectModel: ListingModel }
  ],
  useMultipartFormData: true,
  allowSearch: true,
  crossTableQuery: '&fields=id,code,icon',
  crossRecordViews: [
    { toModel: ListingModel, toExtraQuery: '&fields=id,name,amenities' }
  ],
}

export const OwnerModel: RecordModel<OwnerType> = {
  name: "Owner",
  path: "unit-owners",
  selectQuery: "&expand=owner,unit&fields=id,main_accounting_journal,unit.name,owner.full_name,owner.email&has_no_journals=false",
  tableQuery: "&expand=owner,unit",
  toString: (record: OwnerType) => `${record.owner.full_name} - ${record.unit.name}`
}
