import React, { useState } from "react";
import styled from 'styled-components';
import { Input, Checkbox, Table } from "antd";
import usePaginatedRecordList from "../hooks/usePaginatedRecordList";
import { Identifiable, RecordModel } from "../types/core";


const Container = styled.div`
  height: 100%;

  .ant-table-thead {
    font-size: 12px;
    letter-spacing: -0.5px;
    width: 100%;
  }

  .ant-table-cell {
    text-align: center;
  }

  .to-model-name {
    text-wrap: wrap;
    line-height: 1;
    font-weight: 400;
  }

  .ant-table-cell-fix-left {
    padding: 4px 8px !important;
  }
`;

function getColumns(
  fromModel: RecordModel<any>,
  toRecords: any[],
  toModel: RecordModel<any>,
  deleteRecord: any, patchRecord: any,
  inverted: boolean = false,
  relName?: string,
) {
  const allColsSettings = { ellipsis: true };
  const toCols = toRecords.map(tr => (
    {
      title: <div className="to-model-name">{toModel.toString(tr)}</div>,
      render: (r: any) => {
        let fieldName;
        let val = false;
        if (inverted) {
          fieldName = relName || toModel.path.toLowerCase().replace(/-/g, '');
          val = r[fieldName].includes(tr.id);
        } else {
          fieldName = relName || fromModel.path.toLowerCase().replace(/-/g, '');
          val = tr[fieldName].includes(r.id);
        }
        return (
          <Checkbox checked={val} />
        )
      },
      width: 75
    }
  ))
  return [
    {
      title: fromModel.name,
      render: (r: any) => fromModel.toDescriptor ? fromModel.toDescriptor(r) : fromModel.toString(r),
      width: 125,
      fixed: true
    },
    ...toCols
  ]
    .map((e: any) => ({ ...e, ...allColsSettings }));
}

interface ManyToManyRecordTableProps<FromType, ToType> {
  toModel: RecordModel<ToType>;
  toExtraQuery: string;
  fromModel: RecordModel<FromType>;
  invertedRel?: boolean;
  relName?: string;
  wrapperRef?: any;
}

function ManyToManyRecordTable<FromType, ToType extends Identifiable>(props: ManyToManyRecordTableProps<FromType, ToType>) {
  // const { query } = useQuery();
  const {
    // pagination,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<ToType>({
    //query,
    recordPath: props.fromModel?.path,
    extraQueryAppend: props.fromModel?.crossTableQuery,
    apiService: 'admin',
    pageSize: '100',
    initialAutoFetch: true
  });
  const {
    // pagination,
    isLoading: toLoading,
    recordList: toRecords,
  } = usePaginatedRecordList<ToType>({
    // query,
    recordPath: props.toModel?.path,
    extraQueryAppend: props.toExtraQuery,
    apiService: 'admin',
    pageSize: '100',
    initialAutoFetch: true
  });
  const [highlightUnused, setHighlightUnused] = useState(false);

  return (
    <Container>
      <div className="mb-sm">
        <Checkbox checked={highlightUnused} onChange={e => setHighlightUnused(e.target.checked)}> Highlight unused</Checkbox>
      </div>

      <Table<ToType>
        columns={getColumns(
          props.fromModel,
          toRecords, props.toModel,
          deleteRecord,
          patchRecord,
          props.invertedRel,
          props.relName,
        )}
        dataSource={recordList}
        rowKey="id"
        pagination={false}
        loading={isLoading || toLoading}
        onChange={handleChange}
        scroll={{ y: !!props.wrapperRef ? (props.wrapperRef.current?.clientHeight - 175) : 500 }}
        size="small"
        rowSelection={highlightUnused ? {
          selectedRowKeys: recordList.filter(r => !toRecords.map(r => (r as any)[props.relName || props.fromModel.path.toLowerCase().replace(/-/g, '')]).flat().includes(r.id)).map(r => r.id),
          hideSelectAll: true,
          columnWidth: 0,
          renderCell: () => <></>
        } : undefined}
      />
      <br />
    </Container>
  )
}

export default ManyToManyRecordTable;
