import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import { ancanaApi } from "../api/ancanaApi";
import * as R from "ramda";
import { DatePicker, Drawer, message } from "antd";
import moment from "moment/moment";
import { CheckCircleTwoTone, CloseCircleTwoTone, CommentOutlined } from "@ant-design/icons";
import RecordComments from "./RecordComments";


function HouseOwnerPicksEditor({ houseOwner, houseOwners, year, onClose, visible, assignments, weeksRequired } : any) {
  const [picks, setPicks] = useState<any[]>([]);

  const handleChange = (sort_order: number, pick: any, date?: Moment) => {
    if (date) {
      if (picks.map(p => p.date_start).includes(date.format('YYYY-MM-DD'))) {
        message.error('Error: week is already selected for another pick');
      } else {
        ancanaApi.post('/unit-owner-preferred-date-range/', {
          date_start: date.format('YYYY-MM-DD'),
          date_end: date.add(7, 'days').format('YYYY-MM-DD'),
          year: year,
          sort_order,
          unit_owner: houseOwner.id,
        }).then(res => {
          if (res.status === 201) {
            setPicks([...picks.filter((p: any) => p.sort_order !== sort_order), res.data]);
          }
        });
      }
    } else {
      if (!!pick) ancanaApi.delete(`/unit-owner-preferred-date-range/${pick.id}`).then(res => {
        if (res.status === 204) setPicks(picks.filter((p: any) => p.id !== pick.id))
      });
    }
  }

  useEffect(() => {
    if (houseOwner && year) {
      ancanaApi.get(`/unit-owner-preferred-date-range?unit_owner=${houseOwner.id}&year=${year}&page_size=50`).then(res => {
        setPicks(res.data.results);
        console.log(res.data);
      });
    }
  }, [houseOwner, year]);

  return (
    <Drawer
      title={`${houseOwner?.owner.first_name || houseOwner?.owner.email}'s Preferred Weeks`}
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <div className="fs-2 fw-300 mb-md" style={{ marginTop: -8 }}>Weeks in order of priority</div>
      {R.range(1, weeksRequired + 1).map(n => {
        const currentPick = picks.find((pick: any) => pick.sort_order + 1 === n);
        const weekNumber = moment(currentPick?.date_start)?.week();
        const assignment = assignments.find((assignment: any) => assignment.checkin === currentPick?.date_start);

        const fractionOwner = houseOwners.find((ho: any) => ho.fraction_numbers.includes(assignment?.fraction))
        return (
          <div className="mb-md h-stack-strict center gap-sm" style={{ display: 'flex' }}>
            <div>
              <div className="fw-600">Week {n}</div>
              <DatePicker
                defaultPickerValue={moment().set('month', 0).set('year', year)}
                value={currentPick ? moment(currentPick.date_start) : undefined}
                format="ll"
                disabledDate={(date: Moment) => date.weekday() !== 3}
                onChange={(date: Moment | null) => handleChange(n - 1, currentPick, date || undefined)}
              />
            </div>
            <div className="fs-2">
              <small>Week {weekNumber}</small>
              <div>
                {houseOwner?.fraction_numbers?.includes(assignment?.fraction) ? (
                  <div>
                    <CheckCircleTwoTone twoToneColor="limegreen" /> Round {assignment?.round}
                  </div>
                ) : (
                  <div>
                    <CloseCircleTwoTone twoToneColor="red" /> {fractionOwner?.owner.first_name || `Fraction #${assignment?.fraction}`} @R{assignment?.round}
                  </div>
                )}
              </div>
              {!!currentPick && currentPick.id && (
                <RecordComments
                  className=""
                  recordPath="unit-owner-preferred-date-range"
                  recordId={currentPick.id}
                  recordName={`${year} Picks for ${houseOwner?.owner.full_name || houseOwner?.owner.email}`}
                  buttonProps={{ size: 'small', type: 'link', style: { padding: 0 } }}
                />
              )}
            </div>
          </div>
        );
      })}
    </Drawer>
  )
}

export default HouseOwnerPicksEditor;
