import { Identifiable, RecordModel } from "../core";
import { DesignModel, UnitModel, OwnerModel } from "./housing";
import { Form, Select } from 'antd';
import RecordSelect from "../../components/fields/RecordSelect";
import moment from "moment";
import { UserModel } from "./auth";
import { LocationModel } from "./geo";

export interface IContentType {
  id: number;
  app_label: string;
  model: string;
}

export const ContentTypeModels: { [recordName: string]: RecordModel<any> } = {
  housing_design: DesignModel,
  housing_unit: UnitModel,
  // housing_houseowner: OwnerModel,
  housing_unitowner: OwnerModel,
  auth_user: UserModel,
  geo_location: LocationModel,
}

const app_labels = Object.keys(ContentTypeModels).map(lm => lm.split("_")[0]);
const models = Object.keys(ContentTypeModels).map(lm => lm.split("_")[1]);
export const supportedContentTypesQuery = `&app_labels=${app_labels.filter((item, index) => app_labels.indexOf(item) === index).join(',')}&models=${models.join(",")}`;

export const ContentTypeModel: RecordModel<IContentType> = {
  name: "ContentType",
  namePlural: "ContentTypes",
  path: "content-types",
  selectQuery: supportedContentTypesQuery,
  tableQuery: "",
  toString(r): string {
    return `${r.app_label}: ${r.model}`;
  }
}

const scheduleTypes: any = {
  O: "Once",
  I: "Minutes",
  H: "HOURLY",
  D: "Daily",
  W: "Weekly",
  M: "Monthly",
  Y: "Yearly",
}

export const ScheduleModel: RecordModel<any> = {
  name: "Schedule",
  path: "schedules",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.schedule_type,
  customTableMappings: {
    next_run: { render: r => moment(r.next_run).format("lll") },
    schedule_type: { render: r => scheduleTypes[r.schedule_type] },
  },
  customAction: {
    name: "Run now",
    path: 'run_now',
    isDetail: true
  }
}

const scheduleNullFields = ['name', 'hook', 'args', 'cluster', 'cron', 'minutes'];
const scheduleNullFieldsPlus = [...scheduleNullFields, 'repeats'];
const accountingTasks = [
  { value: "accounting.tasks.create_invoices", label: "Create Invoices" },
  { value: "accounting.tasks.simulate_owner_payment", label: "Simulate Payment (test only)" },
  { value: "accounting.tasks.simulate_owner_cc_payment", label: "Simulate CC Payment (test only)" },
  { value: "accounting.tasks.simulate_str_earning", label: "Simulate STR Earning (test only)" },

  { value: "accounting.tasks.send_payment_reminders", label: "Send Payment Remainders" },
  { value: "accounting.tasks.send_expense_register_reminders", label: "Send Expense Register Reminder" },
]

export const UnitExpenseSchedule: RecordModel<any> = {
  name: "Monthly Task",
  path: "schedules",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.schedule_type,
  customCreateFields: [
    ...scheduleNullFields.map(nullField => ({ name: nullField, component: null })),
    { name: 'schedule_type', component: <Form.Item name="schedule_type" initialValue="M" hidden /> },
    { name: 'repeats', component: <Form.Item name="repeats" initialValue="-1" hidden /> },
    { name: 'kwargs', component: (props: any) => <RecordSelect {...props} formName="kwargs" valueMapper={r => `unit_id=${r.id}`} recordModel={UnitModel} /> },
    { name: 'func', component: () => (
      <Form.Item label="Task" name="func">
        <Select placeholder="Select task to run on a monthly schedule" options={accountingTasks} />
      </Form.Item>)
    },
  ],
  customTableMappings: {
    ...Object.fromEntries(scheduleNullFieldsPlus.map(fieldName => ([fieldName, { omit: true }]))),
    func: { render: r => accountingTasks.find(at => at.value === r.func)?.label, title: "Task" },
    next_run: { render: r => moment(r.next_run).format("lll") },
    schedule_type: { render: r => scheduleTypes[r.schedule_type] },
  },
  customAction: {
    name: "Test",
    path: 'run_now',
    isDetail: true
  }
}

export interface IComment {
  id: number
  author: number
  author_name: string
  content_type: number | IContentType
  object_id: number
  content: string
  data?: {
    special_occasion?: string
  }
  created_at: string
  updated_at: string
}

export const CommentModel: RecordModel<IComment> = {
  customTableMappings: undefined,
  name: "Comments",
  path: "comments",
  selectQuery: "",
  tableQuery: "",
  toString(r: IComment): string {
    return `${r.author} - ${moment(r.created_at).format("lll")}`;
  }
}

export type LocaleCode = 'en' | 'es' | 'fr' | 'it' | 'pt' | 'jp';

export interface ILocale extends Identifiable {
  id: number;
  code: LocaleCode;
  description?: string;
}

export const LocaleModel: RecordModel<ILocale> = {
  name: "Locale",
  path: "locales",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.code
}

export interface ILocalizedText {
  id: number;
  locale: LocaleCode;
  content_type: number;
  object_id: number;
  field_name: string;
  text: string;
  created_at: string;
  modified_at: string;
}
