import React, { useRef, useState } from "react";
import styled from 'styled-components';
import { Badge, Button, DatePicker, Input, PageHeader, Popconfirm, Table } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined } from '@ant-design/icons';
import moment from "moment";

import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import RecordDrawer from "../../components/RecordDrawer";
import { AccountModel, TransactionModel } from "../../types/models/accounting";
import RecordTable from "../../components/RecordTable";
import { useParams } from "react-router-dom";
import RecordSelect from "../../components/fields/RecordSelect";

const { Search } = Input;


const Container = styled.div`
  height: 100%;

  .entry-cell {
    padding: 0 10px;
    border: 1px #f1f1f1 solid;
    border-top: none;
    // height: 100%;
  }
`;

const getColumns = (deleteRecord: any, patchRecord: any) => {
  const allColsSettings = { ellipsis: true };
  return [
    { title: 'ID', dataIndex: 'id', render: (id: number) => <small>{id}</small>, sorter: true, key: 'id', width: 50 },
    // { title: 'Code', dataIndex: ['concept', 'code'], sorter: true, key: 'code', width: 60 },
    {
      title: 'Name/concept',
      render: (r: any) => (
        <div style={{ whiteSpace: 'pre-wrap', fontSize: 13 }}>
          {r.concept?.code}: {r.concept?.name_en}
          <br/>
          {r.name && r.name !== r.concept.name ? `(${r.name})` : ''}
        </div>
      ),
      sorter: true,
      key: 'name',
      width: '20%'
    },
    {
      title: 'Entries',
      render: (r: any) => (
        <div className="disp-flex" style={{ flexDirection: 'row', margin: 4, gap: 8, flexWrap: 'wrap' }}>
          {r.entry_set.map((entry: any) => (
            <div className="disp-flex" style={{ minWidth: 200, maxWidth: 250, boxShadow: '0 1px 1.5px rgba(0,0,0,.15)' }}>
              <div className="disp-flex h-stack-strict justify-between fs-1-5 entry-cell ant-table-cell-ellipsis" style={{ background: '#f8f8f8', letterSpacing: -0.75 }}>
                {entry.account.normal_balance === 'credit_normal' ? 'cn' : 'dn'}: {entry.account.owner_name} {entry.account.name}
              </div>
              <div className="h-stack-strict gap-0 disp-flex">
                <div className="flx1">
                  <div className="entry-cell">Debits</div>
                  <div className="entry-cell fw-600">
                    {entry.direction === 'debit' ? `$${Number(entry.amount).toLocaleString()}` : '-'}
                  </div>
                </div>
                <div className="flx1">
                  <div className="entry-cell">Credits</div>
                  <div className="entry-cell fw-600">
                    {entry.direction === 'credit' ? `$${Number(entry.amount).toLocaleString()}` : '-'}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ),
      width: '42%',
    },
    {
      title: 'Status',
      dataIndex: 'posted_at',
      sorter: true,
      render: (c: string) => (
        <div>
          <Badge status={c ? 'success' : 'processing'} text={c ? 'posted' : 'pending'} />
          <br/>
          {c ? <small>{moment(c).format('ll')}</small> : ''}
        </div>
      ),
      width: 100
    },
    {
      title: 'Effective at',
      dataIndex: 'effective_at',
      sorter: true,
      render: (c: string) => moment(c).format('ll'),
      width: 115
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      sorter: true,
      render: (c: string) => moment(c).format('ll'),
      width: 115
    },
    {
      title: 'Actions', render: (r: any) => (
        <div className="row-actions">
          <Popconfirm
            title={`Permanently delete this record?`}
            onConfirm={() => deleteRecord(r.id)}
            okText="Yes"
          >
            <a><DeleteOutlined /> Delete</a>
          </Popconfirm>
        </div>
      ),
      width: 85
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}


function LedgerDetail() {
  const { ledger_id } = useParams();
  const { query, handleFilter, handleSearch } = useQuery();
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'ledger-transactions',
    extraQueryAppend: '&expand=entry_set.account,concept&ordering=-created_at',
    apiService: 'admin',
    pageSize: '25',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Ledger Transactions"
        subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
        extra={[
          <RecordTable
            modalView
            allowRowSelect
            recordModel={AccountModel}
            recordsetFilters={ledger_id ? { ledger: ledger_id.toString() } : undefined}
          />,
          <RecordDrawer
            drawerWidth={620}
            recordModel={TransactionModel}
            saveAction={createRecord}
            recordsetFilters={ledger_id ? (
              {
                transaction: {
                  ledger: ledger_id.toString()
                },
                entries: {
                  account: {
                    ledger: ledger_id.toString()
                  }
                }
              }
            ) : undefined}
          />
        ]}
        className="mb-md"
      >
        <Search
          placeholder="Search by name, account, amount..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <RecordSelect
          recordModel={AccountModel}
          filter="account"
        />
        <DatePicker.MonthPicker
          defaultValue={!!query.get('effective_at') ? moment(query.get('effective_at')) : undefined}
          onChange={v => handleFilter('effective_at', v ? v.startOf('month').format('YYYY-MM-DD') : '')}
        />
        {selectedRowKeys.length > 0 && (
          <div className="disp-flex flx1 h-stack-strict center gap-xs" style={{ justifyContent: 'flex-end' }}>
            <div>{selectedRowKeys.length} selected:</div>
            {/* <a onClick={() => {}}>Select all {pagination.total} records</a> */}
            <Popconfirm
              title={`Permanently delete ${selectedRowKeys.length} records?`}
              onConfirm={() => {
                selectedRowKeys.forEach(key => {
                  deleteRecord(key);
                });
                setSelectedRowKeys([]);
              }}
              okText="Yes"
              placement="bottomRight"
            >
              <Button type="link" icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        )}
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, patchRecord)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 180 }}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: (selected: any) => {
           setSelectedRowKeys(selected);
          }
        }}
        rowKey="id"
      />
    </Container>
  )
}

export default LedgerDetail;
