import styled from 'styled-components';
import { RecordFieldSchema } from "../types/core";
import { Select } from "antd";
import useQuery from "../hooks/useQuery";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const Container = styled.div`
`;

interface RecordFieldChoicesProps {
  fieldSchema: RecordFieldSchema;
  placeholder?: string;
  onSelect?: (value: any) => void;
  filter?: boolean;
  defaultValue?: any;
  allowClear?: boolean;
  size?: SizeType;
}

function RecordFieldChoices(props: RecordFieldChoicesProps) {
  const { fieldSchema, filter = false } = props;
  const { query, handleFilter } = useQuery();
  const filterName = fieldSchema.label.toLowerCase().replace(/ /g, '_');
  const placeholder = filter ? `Filter by ${fieldSchema.label.toLowerCase()}` : props.placeholder || fieldSchema.help_text || `Select a ${fieldSchema.label.toLowerCase()}`;

  const handleSelectFilter = (value: any) => {
    handleFilter(filterName, value);
  }

  return (
    <Container>
      <Select
        defaultValue={filter ? query.get(filterName) : props.defaultValue}
        allowClear={props.allowClear || true}
        placeholder={placeholder}
        style={{ minWidth: (placeholder?.length || 1) * 7 + 35 }}
        onSelect={props.onSelect || handleSelectFilter}
        onClear={() => handleFilter(filterName, null)}
        size={props.size}
      >
        {fieldSchema.choices?.map((choice) => <Select.Option value={choice.value} key={choice.value}>{choice.display_name}</Select.Option>)}
      </Select>
    </Container>
  )
}

export default RecordFieldChoices;
