import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { Button, Modal, PageHeader, Popconfirm, Table, Tag, Image, Input, Tabs, type TabsProps } from "antd";

import useQuery from "../hooks/useQuery";
import usePaginatedRecordList from "../hooks/usePaginatedRecordList";
import { Identifiable, RecordModel, RecordsetFilters } from "../types/core";
import RecordDrawer from "./RecordDrawer";
import RecordGalleriesModal from "./RecordGalleriesModal";
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, SettingOutlined, LoginOutlined, EditOutlined, FolderAddOutlined } from "@ant-design/icons";
import GenericRelationLabel from "./GenericRelationLabel";
import { ButtonType } from "antd/lib/button/button";
import RecordSelect from "./fields/RecordSelect";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import RecordFieldChoices from "./RecordFieldChoices";
import RelatedFieldCell from "./fields/RelatedFieldCell";
import RecordCustomAction from "./RecordCustomAction";
import ManyToManyRecordTable from "./ManyToManyRecordTable";

const { Search } = Input;

const Container = styled.div<any>`
  height: 100%;

  .ant-table-pagination {
    position: ${({ fullpage }) => fullpage ? 'relative' : 'sticky'};
    bottom: 0;
    background: ${({ fullpage }) => fullpage ? 'transparent' : 'white'};
    // border-top: 1px #efefef solid;
    box-shadow: ${({ fullpage }) => fullpage ? 'none' : '0 -5px 6px -6px rgba(0,0,0,.15)'};
    margin: 0;
    padding: 8px 0;
  }

  .ant-table-footer {
    padding: ${({ footer }) => !!footer ? '8px' : '0'} !important;
  }

  .ant-tabs-tab {
    border-color: #dadada !important;
    background-color: rgba(0,0,0,.04) !important;
  }

  .ant-tabs-tab-active {
    border-bottom-color: #f0f2f5 !important;
    background-color: transparent !important;
  }

  .ant-tabs-nav::before {
    border-color: #dadada !important;
  }
`;

const MIN_WIDTH_COL = 75;
const MIN_WIDTH_COL_FULL = 100;
const MAX_WIDTH_COL = 350;
const MAX_WIDTH_COL_FULL = 500;

const getColumns = <RecordType extends Identifiable,>(
  fullpage = false,
  recordFields = {},
  deleteRecord: any,
  setRecordToEdit: any,
  navigate: any,
  recordModel?: RecordModel<RecordType>,
  recordsetFilters?: any,
  doRecordAction?: any,
  viewOnly?: boolean,
) => {
  const customMappings: any = recordModel?.customTableMappings;
  const allColsSettings = { ellipsis: true };

  const defaultCols: any[] = Object.entries(recordFields)
    .filter(([k, v]: any) => (!v.read_only || v.read_only || k === 'id' || (!!customMappings && !!customMappings[k])))
    .filter(([k, v]: any) => k !== 'cover_image')
    .filter(([k, v]: any) => !v.write_only)
    .filter(([k, v]) => customMappings ? !customMappings[k]?.omit : true)
    .filter(([k, v]: any) => !!recordsetFilters ? !Object.keys(recordsetFilters).includes(k) : true)
    .filter(([k, v]: any) => k !== 'object_id')
    .sort(([k, v]: any, [k2, v2]: any) => {
      let a: any = 100;
      let b: any = 100;
      if (!!customMappings) {
        a = customMappings[k]?.sort_index !== undefined ? customMappings[k]?.sort_index : 100;
        b = customMappings[k2]?.sort_index !== undefined ? customMappings[k2]?.sort_index : 100;
      }
      return a - b;
    })
    .map(([fieldName, fieldSchema]: any) => {
      const title = customMappings && customMappings[fieldName]?.title ? customMappings[fieldName]?.title : fieldSchema.label;
      let width = undefined;
      if (fieldName === 'id') {
        width = 50
      } else {
        if (customMappings && customMappings[fieldName]?.width) {
          width = customMappings[fieldName]?.width;
        }
      }
      const customRender = customMappings ? customMappings[fieldName]?.render : null;
      const customPath = customMappings ? customMappings[fieldName]?.path : undefined;
      const dataIndex = customPath || fieldName;

      if (fieldName.includes('amount') || fieldName.includes('price') || fieldName.includes('balance')) {
        return  { title, dataIndex, render: (r: any) => <>${Number(r).toLocaleString()}</>, width }
      }
      if (fieldSchema.type === 'file upload') {
        return  {
          title, dataIndex,
          render: (fileUrl: any) => fileUrl && /\.(jpeg|jpg|gif|png|bmp|webp|svg)$/i.test(fileUrl) ? <Image src={fileUrl} width={25} /> : fileUrl,
          width
        }
      }
      if (fieldSchema.type === 'boolean') {
        const titleWidth = title.length * 10;
        return {
          title,
          dataIndex,
          render: (r: boolean) => r ? <CheckCircleTwoTone twoToneColor="limegreen" /> : <CloseCircleTwoTone twoToneColor="red" />,
          width: titleWidth > 60 ? titleWidth : 60
        }
      }
      if (fieldName.includes('content_type')) {
        return {
          title: (r: any) => !!r.object_id ? "Related object" : title,
          render: (r: any) => {
            if (!!r.object_id) {
              return <GenericRelationLabel content_type={r.content_type} object_id={r.object_id} />
            } else {
              return typeof r[fieldName] === 'number' ? r[fieldName] : `${r[fieldName].app_label} | ${r[fieldName].model}`;
            }
          },
          width
        }
      }
      if (fieldName === 'id') {
        return {
          title: "ID",
          render: (r: any) => <p className="fs-1 pre-line" style={{ margin: 0 }}>{r.id}</p>,
          width
        }
      }
      if (fieldName === 'created_at' || fieldName === 'updated_at' || fieldName === 'modified_at' || fieldName.includes('date')) {
        return { title, dataIndex, render: (r: any) => moment(r).format('ll'), width: 115 }
      }
      if (recordModel?.detailLinkField === fieldName) {
        return { title, render: (r: any) => <a href={`${window.location.href}/${r.id}/`}>{r[fieldName]}</a>, width }
      }
      if (customRender) {
        return { title, render: customRender, width };
      }
      if (fieldSchema.type === 'field' && !fieldSchema.child && recordModel && !fieldSchema.read_only) {
        return {
          title,
          render: (r: RecordType) => (
            <RelatedFieldCell<RecordType>
              fieldName={fieldName}
              fieldDefinition={fieldSchema}
              record={r}
              recordModel={recordModel}
            />
          ),
          width
        }
      }
      return { title, dataIndex, width };
    });

  if (Object.hasOwn(recordFields, 'cover_image')) {
    defaultCols.splice(1, 0, {
      title: 'Cover', dataIndex: 'cover_image',
      render: (imgUrl: string) => (
        <div style={{ margin: -8, height: 56 }}>
          <div
            style={{
              background: `url("${imgUrl}") center center / cover`,
              height: '100%', width: '100%',
              top: 0,
              zIndex: 0,
              position: 'absolute'
            }}
          />
          <Image
            width={56}
            height={56}
            style={{ zIndex: 1 }}
            preview={{
              src: imgUrl,
            }}
          />
        </div>
      ),
      key: 'cover_image', width: 56 },);
  }

  let actionsWidth: number | undefined = 135;
  if (recordModel?.hasImageGalleries) actionsWidth += 80;
  if (!!recordModel?.customAction && recordModel.customAction.isDetail) actionsWidth += 60;
  if (!!recordModel?.detailAction) actionsWidth += 75;
  if (!!recordModel?.actionsChildren) actionsWidth = undefined;

  const actionsCol = {
    title: 'Actions',
    render: (r: any) => (
      <div className="row-actions">
        {recordModel?.hasImageGalleries && (
          <RecordGalleriesModal recordName={r.name} recordPath={recordModel.path} recordId={r.id}>
            <a><FolderAddOutlined /> Gallery</a>
          </RecordGalleriesModal>
        )}
        {(!!recordModel?.customAction && recordModel.customAction.isDetail) && (
          <RecordCustomAction<RecordType> recordModel={recordModel} record={r} doRecordAction={doRecordAction} />
        )}
        {!!recordModel?.detailAction && (
          <a onClick={() => navigate(`${typeof recordModel.detailAction === 'string' ? recordModel.detailAction+'/' : ''}${r.id}`)}>
            <LoginOutlined /> Detail
          </a>
        )}
        <a onClick={() => setRecordToEdit(r)}><EditOutlined /> Edit</a>
        {recordModel?.actionsChildren}
        <Popconfirm
          title={`Permanently delete this record?`}
          onConfirm={() => deleteRecord(r.id)}
          okText="Yes"
        >
          <a><DeleteOutlined /> Delete</a>
        </Popconfirm>
      </div>
    ),
    width: actionsWidth,
  };

  return (!!viewOnly ? defaultCols : defaultCols.concat(actionsCol)).map((e: any) => ({ ...e, ...allColsSettings }));
}

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <DefaultTabBar {...props} className="sidento sticky-tab-bar" style={{ height: 32 }} />
);

interface RecordTableProps<RecordType> {
  recordModel: RecordModel<RecordType>;
  recordsetFilters?: RecordsetFilters;
  customRecordName?: string;
  className?: string;
  footer?: (records: RecordType[]) => ReactNode;
  noPagination?: boolean;
  fullpage?: boolean;
  modalView?: boolean;
  btnSize?: "small" | "middle" | "large";
  btnBlock?: boolean;
  btnType?: ButtonType;
  onBtnClick?: any;
  children?: ReactNode;
  highlightRecords?: number[] | string[];
  stickyTop?: number;
  viewOnly?: boolean;
  firstFetchQueryAppend?: object;
  size?: SizeType;
  extraQuery?: string;
  allowRowSelect?: boolean;
}

function RecordTable<RecordType extends Identifiable>(props: RecordTableProps<RecordType>) {
  const navigate = useNavigate();
  const { query, handleFilter, handleSearch } = useQuery();
  const [currentTab, setCurrentTab] = useState(query.get('tab') || 'all');
  const { recordModel, recordsetFilters, extraQuery = '', allowRowSelect = false, modalView = false } = props;
  const { recordModel: { crossRecordViews = undefined } } = props;
  const filtersQuery = recordsetFilters ? '&' + Object.entries(recordsetFilters || {}).map(([k, v]) => `${k}=${v}`).join('&') : '';
  const highlightsQuery = props.highlightRecords ? `&highlights=${props.highlightRecords.join(',')}` : '';
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList,
    recordFields,
    firstFetch,
    doRecordAction
  } = usePaginatedRecordList<any>({
    query,
    recordPath: recordModel.path,
    extraQueryAppend: recordModel.tableQuery + highlightsQuery + filtersQuery + extraQuery,
    apiService: 'admin',
    initialOptionsFetch: true
  });
  const elementRef: any = useRef(null);
  const [recordToEdit, setRecordToEdit] = useState<RecordType | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const getTableViewHeightOffset = () => {
    let defaultOffset = 130;
    if (!!props.recordModel.tableFilters || !!props.recordModel.allowSearch) {
      defaultOffset += 30;
    }
    if (crossRecordViews) {
      defaultOffset += 38;
    }
    return defaultOffset;
  }
  const filterSize = crossRecordViews ? 'small' : 'middle';
  const recordsPluralName = props.customRecordName || (recordModel.namePlural ? recordModel.namePlural : `${recordModel.name}s`);

  useEffect(() => {
    if (currentTab === 'all' && recordList.length === 0) {
      if (props.firstFetchQueryAppend) {  // I don't think this is ever used
        Object.entries(props.firstFetchQueryAppend).forEach(([k, v]) => {
          if (!query.get(k)) {
            handleFilter(k, v, true);
          }
        });
      }
      firstFetch();
    }
  }, [currentTab]);

  useEffect(() => {
    if (recordsetFilters) {
      firstFetch();
    }
  }, [recordsetFilters?.unit]);

  useEffect(() => {
    if (props.highlightRecords && props.highlightRecords.length > 0) {
      firstFetch();
    }
  }, [props.highlightRecords]);

  const MainRecordTable = (
    <>
      <div className="disp-flex h-stack-strict center gap-xs mb-md">
      {props.recordModel.allowSearch && (
        <Search
          placeholder="Search..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
          size={filterSize}
        />
      )}
      {recordsetFilters && (
        <div style={{ display: 'flex' }}>
          {Object.entries(recordsetFilters).map(([k, v]) => {
            return <Tag style={{ display: 'flex', alignItems: 'center' }}><>{k}: {v}</></Tag>
          })}
        </div>
      )}
      {!modalView && props.recordModel.tableFilters && props.recordModel.tableFilters.map(tFilter => {
        if (tFilter.selectModel !== undefined) {
          return (
            <RecordSelect
              recordModel={tFilter.selectModel}
              filter
              autoSelectFirst={tFilter.autoSelectFirst || false}
              extraFilterQuery={tFilter.extraFilterQuery}
              size={filterSize}
              {...tFilter.selectModelProps}
            />
          )
        }
        if (tFilter.component !== undefined) {
          return tFilter.component({ query, handleFilter, handleSearch }, { size: filterSize });
        }
        if (tFilter.selectField !== undefined && !!recordFields && !!recordFields[tFilter.selectField] && recordFields[tFilter.selectField].type === 'choice') {
          return (
            <RecordFieldChoices size={filterSize} fieldSchema={recordFields[tFilter.selectField]} filter />
          )
        }
        return null;
      })}
      </div>
      {selectedRowKeys.length > 0 && (
        <div className="disp-flex flx1 h-stack-strict center gap-xs" style={{ justifyContent: 'flex-end' }}>
          <div>{selectedRowKeys.length} selected:</div>
          {/* <a onClick={() => {}}>Select all {pagination.total} records</a> */}
          <Popconfirm
            title={`Permanently delete ${selectedRowKeys.length} records?`}
            onConfirm={() => {
              selectedRowKeys.forEach(key => {
                deleteRecord(key);
              });
              setSelectedRowKeys([]);
            }}
            okText="Yes"
            placement="bottomRight"
          >
            <Button type="link" icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      )}
    <Table
      columns={getColumns<RecordType>(
        props.fullpage,
        recordFields,
        deleteRecord,
        setRecordToEdit,
        navigate,
        recordModel,
        recordsetFilters,
        doRecordAction,
        props.viewOnly,
      )}
      dataSource={recordList}
      rowKey="id"
      pagination={props.noPagination ? false : pagination}
      loading={isLoading}
      onChange={handleChange}
      scroll={{ y: elementRef.current?.clientHeight - getTableViewHeightOffset() }}
      size={props.size || 'small'}
      footer={() => props.footer ? props.footer(recordList) : (modalView ? <div style={{ height: 4 }} /> : null)}
      sticky={{ offsetHeader: props.stickyTop || 0 }}
      summary={() => (<Table.Summary fixed="top"><Table.Summary.Row /></Table.Summary>)}
      rowSelection={allowRowSelect ? {
        selectedRowKeys,
        onChange: (selected: any) => {
          setSelectedRowKeys(selected);
        }
      } : props.highlightRecords ? {
        selectedRowKeys: props.highlightRecords,
        hideSelectAll: true,
        columnWidth: 0,
        renderCell: () => <></>
      } : undefined}
    />
    </>
  )

  const TableCont = (
    <Container
      ref={!!props.fullpage ? elementRef : null}
      className={props.className+' anti-contento'}
      fullpage={props.fullpage}
      footer={props.footer}
    >
      <div className="contento pb-sm">
        <PageHeader
          title={recordsPluralName}
          subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
          extra={!!props.viewOnly ? [] : [
            (
              (recordModel.customAction !== undefined && !recordModel.customAction.isDetail) ? (
                <Button
                  icon={recordModel.customAction.icon as ReactNode}
                  onClick={() => doRecordAction((recordModel.customAction as any).path)}
                >
                  {recordModel.customAction.name as string}
                </Button>
              ) : undefined
            ),
            <RecordDrawer<RecordType>
              recordModel={recordModel}
              customCreateFields={recordModel.customCreateFields}
              saveAction={createRecord}
              recordsetFilters={props.recordsetFilters}
              customRecordName={props.customRecordName}
              disabled={isLoading}
            />,
          ]}
        />
      </div>

      {crossRecordViews !== undefined ? (
        <Tabs
          renderTabBar={renderTabBar}
          defaultActiveKey="all"
          activeKey={currentTab}
          className=""
          type="card"
          tabBarGutter={4}
          onTabClick={(key) => {
            // handleFilter('tab', key, true);
            const newUrl = window.location.href.split('?')[0] + '?tab='+key;
            window.history.pushState({ path: newUrl }, '', newUrl);
            setCurrentTab(key);
          }}
        >
          <Tabs.TabPane
            closable={false}
            tab={`All ${recordsPluralName.toLowerCase()}`}
            key="all"
            className="sidento"
          >
            {MainRecordTable}
          </Tabs.TabPane>
          {recordModel.crossRecordViews?.map(crv => (
            <Tabs.TabPane
              tab={crv.name || `${recordsPluralName} per ${crv.toModel?.name.toLowerCase()}`}
              key={crv.toModel?.path}
              className="sidento"
            >
              <ManyToManyRecordTable
                fromModel={recordModel}
                toModel={crv.toModel}
                toExtraQuery={crv.toExtraQuery}
                relName={crv.relName}
                invertedRel={crv.invertedRel}
                wrapperRef={elementRef}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <div className="sidento">
          {MainRecordTable}
        </div>
      )}

      <RecordDrawer<RecordType>
        recordModel={recordModel}
        customCreateFields={recordModel.customCreateFields}
        saveAction={patchRecord}
        recordsetFilters={props.recordsetFilters}
        customRecordName={props.customRecordName}
        recordInstance={recordToEdit}
        onClose={() => setRecordToEdit(null)}
      />
    </Container>
  )

  if (modalView) {
    return (
      <>
        <Button
          block={props.btnBlock || false}
          size={props.btnSize}
          type={props.btnType}
          onClick={() => {
            setModalVisible(true);
            if (props.onBtnClick) props.onBtnClick();
          }}
        >
          {props.children ? (
            props.children
          ) : (
            <>
              {recordModel.icon || <SettingOutlined color="#fff0ff" />}
              <span> Manage {recordModel.namePlural ? recordModel.namePlural : `${recordModel.name}s`}</span>
            </>
            )
          }
        </Button>
        <Modal
          visible={modalVisible}
          onCancel={() => { setModalVisible(false); firstFetch(); }}
          footer={null}
          width={1100}
          title={(
            <div className="disp-flex h-stack-strict gap-xs center">
              {recordModel.icon || <SettingOutlined color="#fff0ff" />}
              <div>Manage {recordModel.name} Records</div>
            </div>
          )}
        >
          {TableCont}
        </Modal>
      </>
    )
  } else {
    return TableCont;
  }
}

export default RecordTable;
