import { CustomFieldProps, Identifiable, RecordModel, RecordsetFilters } from "../types/core";
import FormDrawer from "./FormDrawer";
import usePaginatedRecordList from "../hooks/usePaginatedRecordList";
import RecordFieldsMapper from "./RecordFieldsMapper";
import { ReactNode, useEffect, useRef } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

interface RecordDrawerProps<RecordType>{
  recordModel: RecordModel<RecordType>;
  customCreateFields?: { name: string, component: ReactNode | ((props: CustomFieldProps<RecordType>) => ReactNode) }[];
  saveAction?: any;
  recordsetFilters?: RecordsetFilters;
  customRecordName?: string;
  recordInstance?: RecordType | null;
  onClose?: any;
  drawerWidth?: number;
  disabled?: boolean;
  customButton?: any;
  customAction?: any;
}

const nestedRecordToFormData = (record: any) => {
  let formData: any = {};
  Object.entries(record).forEach(([k, v]: [string, any]) => {
    let value = v;
    if (typeof v === 'object') {
      value = v?.id?.toString();
    }
    if (typeof v === 'number') {
      value = v?.toString();
    }
    if (Array.isArray(v)) {
      value = v.map(i => {
        if (typeof i === 'object') {
          return i.id.toString();
        }
        return i.toString();
      });
    }
    formData[k] = value;
  });
  return formData;
}

function RecordDrawer<RecordType extends Identifiable>(props: RecordDrawerProps<RecordType>) {
  const {
    patchRecord,
    createRecord,
    recordFields,
    fetchRecordLocalizedTexts,
    doRecordAction,
    contentType,
  } = usePaginatedRecordList<any>({
    recordPath: props.recordModel.path,
    apiService: 'admin',
    initialOptionsFetch: true,
    optionsRecordId: !!props.customAction?.path ? props.recordInstance?.id : undefined,
    optionsPath: props.customAction?.path
  });
  const {
    recordModel,
    recordInstance,
    customCreateFields = []
  } = props;
  const formDrawerRef = useRef<any>();
  const isEdit = !!recordInstance && !props.customAction;
  const isAction = !!props.customAction;
  const saveAction = props.saveAction || (
    !!props.customAction ? (
      (id: any, data: any) => doRecordAction(props.customAction.path, props.customAction.method, data, props.recordInstance?.id)
    ) : (
      recordInstance ? patchRecord : createRecord
    ));

  useEffect(() => {
    if (!!props.recordsetFilters) {
      formDrawerRef.current.setFieldsValue(props.recordsetFilters);
    }
  }, [props.recordsetFilters]);

  useEffect(() => {
    formDrawerRef.current.setFieldsValue(props.recordModel.defaultValues);
  }, [props.recordModel]);

  useEffect(() => {
    if (isEdit) {
      formDrawerRef.current.open();
      // const mappedRecord = recordModel.customFormMapper ? recordModel.customFormMapper(recordInstance) : recordInstance;
      // console.log('??>>', recordModel.name, recordInstance, nestedRecordToFormData(recordInstance));
      formDrawerRef.current.setFieldsValue(nestedRecordToFormData(recordInstance));

      // Get all language texts for edit
      if (Object.keys(recordFields || {}).some(recordKey => recordKey.includes('__'))) {
        const localizedTexts: any = {};
        Object.keys(recordFields || {}).filter(recordKey => recordKey.includes('__')).forEach(locKey => {
          localizedTexts[locKey] = null;
        });
        fetchRecordLocalizedTexts(Number(recordInstance.id)).then(texts => {
          texts.forEach(lt => localizedTexts[`${lt.field_name}__${lt.locale}`] = lt.text);
          formDrawerRef.current.setFieldsValue(localizedTexts);
        });
      }
    }
    if (isAction) {
      formDrawerRef.current.setFieldsValue(nestedRecordToFormData(recordInstance));
    }
  }, [recordInstance]);

  return (
    <div>
      <FormDrawer
        ref={formDrawerRef}
        customButton={!!props.customAction ? props.customButton : recordInstance === undefined ? undefined : <></>}
        recordId={recordInstance ? (recordInstance as any)[props.recordModel.primaryKeyName || 'id'] : undefined}
        onClose={props.onClose}
        actionName={isAction ? (props.customAction.name || 'Perform') : isEdit ? 'Edit' : 'Create'}
        recordName={props.customRecordName || recordModel.name}
        btnIcon={recordInstance ? <EditOutlined /> : <PlusOutlined />}
        saveButtonText={recordInstance ? 'Save' : undefined}
        handleRecordAction={saveAction}
        primaryKeyName={recordModel.primaryKeyName}
        drawerWidth={props.drawerWidth || recordModel.drawerWidth}
        afterFormReset={() => {
          if (!!props.recordsetFilters) {
            formDrawerRef.current.setFieldsValue(props.recordsetFilters);
          }
        }}
        disabled={props.disabled}
        useMultipartFormData={recordModel.useMultipartFormData}
      >
        <RecordFieldsMapper<RecordType>
          fieldDefinitions={recordFields}
          customCreateFields={[...(recordModel.customCreateFields ? recordModel.customCreateFields : []) , ...customCreateFields]}
          recordModel={recordModel}
          setFieldsValue={formDrawerRef.current?.setFieldsValue}
          recordsetFilters={props.recordsetFilters}
          formRef={formDrawerRef}
          recordInstance={!!recordInstance ? recordInstance : undefined}
          contentType={contentType}
        />
      </FormDrawer>
    </div>
  )
}

export default RecordDrawer;
