import styled from 'styled-components';
import { Button, Modal, Steps, Form, Select, Radio, Input, InputNumber } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  HomeOutlined,
  CodepenOutlined,
  BuildOutlined,
  PlusOutlined
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import RecordSelect from "../fields/RecordSelect";

import { AmenityModel, DesignModel, UnitModel, IDesign, IUnit } from "../../types/models/housing";
import RecordTable from "../RecordTable";
import { IUnitCapex, IUnitOpex, UnitCapexModel, UnitOpexModel } from "../../types/models/economics";
import { LocationModel } from "../../types/models/geo";
import { ancanaApi } from "../../api/ancanaApi";
import RecordGalleries from "../RecordGalleries";
import { difference, reject, omit } from "ramda";
// const fs = require('fs');


const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 200px);

  .steps-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 16px;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    height: calc(100% - 24px);
    align-content: center;
    overflow-y: scroll;
    // justify-content: center;
  }
  label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .uploader {
    width: 100%;
  }

  .ant-upload-list {

  }

  .ant-radio {
    margin-left: 12px;
    align-self: flex-start;
    margin-top: 10px;
  }

  .radio-box {
    border: 1px rgba(0,0,0,.12) solid;
    margin-left: -36px;
    margin-right: 0;
    padding: 12px 16px;
    text-align: center;
    width: 210px;
    height: 172px;
  }

  .ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
    top: 0;
  }
`;

interface ListingWizardProps {
  createRecord: any;
  onSuccess?: any;
  record?: any | null;
  setRecord?: any;
  patchRecord?: any;
  onClose?: any;
}

function ListingWizard(props: ListingWizardProps) {
  const { onSuccess, createRecord, record, setRecord, onClose } = props;
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [saving, setSaving] = useState(false);
  const [formValsCopy, setFormValsCopy] = useState<any>({});
  const [relatedRecordFilter, setRelatedRecordFilter] = useState<any>({});
  const [form] = Form.useForm();

  const reset = () => {
    setCurrent(0);
    setFormValsCopy(undefined);
    form.resetFields();
  }

  const isNextDisabled = () => {
    if (formValsCopy === undefined){
      return true;
    }
    if (current === 0) {
      return !(formValsCopy['type'] && formValsCopy['unit']);
    }
    if (current === 1) {
      // return !(formValsCopy['unit']);
      return !(formValsCopy['amenities'] && formValsCopy['location'] && formValsCopy['status'] && formValsCopy['lat'] && formValsCopy['lng']);
    }
  }

  const handleFinish = async (data: any) => {
    setSaving(true);
    const res = await ancanaApi.get(`housing-units/${data['unit']}`);
    const payload = {
      name: res.data.name,
      slug: res.data.name
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, ''),
      localized_data: {
        descriptions: {
          es: data.description_es || "",
          en: data.description_en || "",
        }
      },
      ...data,
    };
    if(!!record) {
      // omit(difference(Object.keys(record), Object.keys(data)), record)
      props.patchRecord(record.slug, reject((val) => val === null, data));
      setCurrent(0);
      setRecord(null);
    } else {
      createRecord(payload, reset);
      setOpen(false);
    }
    setSaving(false);
    if(onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (formValsCopy !== undefined && !!formValsCopy['unit']) {
      setRelatedRecordFilter({ unit: formValsCopy['unit'] });
    } else {
      setRelatedRecordFilter({});
    }
  }, [formValsCopy?.unit]);

  useEffect(() => {
    if (!!record) {
      setOpen(true);
      const initialFormValues = {
        ...record,
        unit: record.unit.toString(),
        location: record.location.id.toString(),
        amenities: record.amenities.map((am: number) => am.toString()),
      };
      form.setFieldsValue(initialFormValues);
      setFormValsCopy(initialFormValues);
    } else {
      setOpen(false);
    }
  }, [record]);

  return (
    <>
      {record === undefined && (
      <Button type="primary" onClick={() => setOpen(true)} icon={<PlusOutlined />}>
        Create Listing
      </Button>
      )}
      <Modal
        title={`${!!record ? 'Edit' : 'Create'} Listing${!!record && `: ${record.name}`}`}
        centered
        visible={open}
        width="900px"
        onCancel={() => {
          if (!!record) {
            setRecord(null);
          } else {
            setOpen(false);
            setCurrent(0);
          }
          if(onClose) {
            onClose();
          }
        }}
        footer={
          <div className="full-width justify-between" style={{ display: 'flex' }}>
            <Button
              type="primary"
              ghost
              disabled={current === 0}
              onClick={() => setCurrent(current - 1)}
            >
              <LeftOutlined /> Back
            </Button>
            {current < 3 && (
              <Button
                type="primary"
                onClick={() => {
                  setCurrent(current + 1);
                }}
                disabled={isNextDisabled()}
              >
                Next <RightOutlined />
              </Button>
            )}
            {current === 3 && (
              <Button type="primary" onClick={() => form.submit()} loading={saving}>
                {!!record ? 'Save Changes' : 'Create Listing'}
              </Button>
            )}
          </div>
        }
      >
        <Container>
          <Steps current={current}>
            <Steps.Step title="Type" />
            <Steps.Step title="Listing details" />
            <Steps.Step title="Pricing & expenses" />
            <Steps.Step title="Images" />
          </Steps>

          <Form
            form={form}
            onValuesChange={(newData) => setFormValsCopy({ ...formValsCopy, ...newData })}
            onFinish={handleFinish}
            layout="vertical"
            style={{ height: '100%', width: '100%', position: 'absolute' }}
          >
            <div className="steps-content" style={{ visibility: current === 0 ? 'visible' : 'hidden', position: 'absolute' }}>
            <div className="fs-3-5 fw-500 mt-lg">
              Select type
            </div>
            <p>
              Is the listing gonna be for a single unit or multi-unit?
            </p>

            <Form.Item name="type">
            <Radio.Group>
              <Radio value="unit">
                <div className="radio-box">
                  <HomeOutlined style={{ fontSize: 24, color: 'rgba(0,0,0,.36)', marginBottom: 8 }} />
                  <div className="fs-3-5 mb-sm">Unit</div>
                  <div className="fs-2-33 fw-400">
                    The listing will show information of a single unit
                  </div>
                </div>
              </Radio>
              <Radio value="design" disabled>
                <div className="radio-box">
                  <CodepenOutlined style={{ fontSize: 24, color: 'rgba(0,0,0,.36)', marginBottom: 8 }} />
                  <div className="fs-3-5 mb-sm">Design</div>
                  <div className="fs-2-33 fw-400">
                    Multi-unit listing with shared design/model
                  </div>
                </div>
              </Radio>
              <Radio value="development" disabled>
                <div className="radio-box">
                  <BuildOutlined style={{ fontSize: 24, color: 'rgba(0,0,0,.36)', marginBottom: 8 }} />
                  <div className="fs-3-5 mb-sm">Development</div>
                  <div className="fs-2-33 fw-400">
                    Multi-design listing with shared development
                  </div>
                </div>
              </Radio>
            </Radio.Group>
            </Form.Item>
              {!!formValsCopy && !!formValsCopy['type'] && (
            <RecordSelect<IUnit>
              recordModel={UnitModel}
              allowDrawerCreate
              allowManage
              customCreateFields={[{ name: 'design', component: <RecordSelect<IDesign> recordModel={DesignModel} allowCreate /> }]}
              onCreate={data => {
                form.setFieldsValue({unit: data.id.toString()});
                setFormValsCopy({ ...formValsCopy, unit: data.id.toString() });
              }}
              highlightRecords={formValsCopy['unit'] ? [Number(formValsCopy['unit'])] : undefined}
            />)}
          </div>
            <div className="steps-content" style={{ visibility: current === 1 ? 'visible' : 'hidden', position: 'absolute' }}>
              <div className="mt-lg fw-500 fs-3-5">
                Listing details
              </div>
              <p>
                Latitude and longitude can be taken from google maps and must have 6 decimal digits.
              </p>

              {/*
              <Form.Item label="Listing name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              */}

              <RecordSelect
                label="Amenities"
                mode="multiple"
                formName="amenities"
                recordModel={AmenityModel}
                allowManage
              />

              <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                <Select placeholder="Select the listing status">
                  <Select.Option value="pre_sale">Pre Sale</Select.Option>
                  <Select.Option value="new_arrival">New Arrival</Select.Option>
                  <Select.Option value="available_now">Available Now</Select.Option>
                  <Select.Option value="sold">Sold</Select.Option>
                </Select>
              </Form.Item>

              <RecordSelect
                // label="Location (used in the site for filtering)"
                recordModel={LocationModel}
                allowCreate
              />

              <Input.Group compact>
                <Form.Item
                  name="lat"
                  label="Latitude"
                  style={{ width: 'calc(50% - 10px)', marginRight: 20 }}
                  rules={[{ required: true }]}
                >
                  <InputNumber max={999} min={-999} precision={6} step="0.000001" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="lng"
                  label="Longitude"
                  style={{ width: 'calc(50% - 10px)' }}
                  rules={[{ required: true }]}
                >
                  <InputNumber max={999} min={-999} precision={6} step="0.000001" style={{ width: '100%' }} />
                </Form.Item>
              </Input.Group>

              <Form.Item
                name="address"
                label="Address Line"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description_en"
                label="English description"
              >
                <Input.TextArea rows={7} />
              </Form.Item>
              <Form.Item
                name="description_es"
                label="Spanish description"
              >
                <Input.TextArea rows={7} />
              </Form.Item>
            </div>

            <div className="steps-content" style={{ visibility: current === 2 ? 'visible' : 'hidden', position: 'absolute' }}>
              {/*
            <div className="mt-lg fw-500 fs-3-5">
              Select or create object
            </div>
            <p>
              Pricing details and operational expenses can be added once a unit is selected.
            </p>
            {!!formValsCopy && !!formValsCopy['unit'] && (
              <><br /><Divider dashed /><br /></>
            )}
            */}

              {!!formValsCopy && !!formValsCopy['unit'] && (
                <RecordTable<IUnitCapex>
                  className="mt-lg"
                  recordModel={UnitCapexModel}
                  recordsetFilters={relatedRecordFilter}
                  customRecordName="Pricing Details"
                  footer={(records) => (
                    <div className="fs-2-66">
                      Final Price <span className="fw-600">${records.reduce((a, c) => Number(c.amount) + a, 0).toLocaleString()}</span>
                    </div>
                  )}
                  noPagination
                />
              )}
              <br /><br />
              {!!formValsCopy && !!formValsCopy['unit'] && (
                <RecordTable<IUnitOpex>
                  recordModel={UnitOpexModel}
                  recordsetFilters={relatedRecordFilter}
                  customRecordName="Expenses"
                  footer={(records) => (
                    <div className="fs-2-66">
                      Total Expenses <span className="fw-600">${records.reduce((a, c) => Number(c.amount) + a, 0).toLocaleString()}</span>
                    </div>
                  )}
                  noPagination
                />
              )}
              <br /><br /><br/>
            </div>
          </Form>

          {current === 3 && (
            <div className="steps-content" style={{ position: 'absolute' }}>
              <div className="mt-lg fw-500 fs-3-5 capitalize">
                {formValsCopy['type']} images
              </div>
              <p>
                Each image gallery is a tab, these galleries are for grouping images (for example: Kitchen, Front)
              </p>
              <RecordGalleries recordId={formValsCopy['unit']} recordName="House" recordPath="housing-units" />
            </div>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default ListingWizard;
