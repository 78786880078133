import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import { PageHeader, Button, Tabs, Statistic, Dropdown, Menu, Space, Card, Modal, Form, Table, Badge } from "antd";
import { BrowserRouter } from "react-router-dom";
import { DownOutlined, UserOutlined, HomeOutlined } from "@ant-design/icons";
import RecordTable from "../../components/RecordTable";
import {
  ExpenseConceptUnitSettingsModel,
  ILedgerCheckpoint,
  InvoiceModel, ITransactionModel,
  LedgerCheckpointModel,
  TransactionModelModel
} from "../../types/models";
import type { TabsProps } from 'antd';
import ExpenseTransactionsEditor from "../../components/business/ExpenseTransactionsEditor";
import { useNavigate, useParams } from "react-router-dom";
import { ancanaApi } from "../../api/ancanaApi";
import moment from "moment";
import RecordSelect from "../../components/fields/RecordSelect";
import OwnerExpenses from "../../components/business/OwnerExpenses";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { OwnerModel } from "../../types/models";
import useQuery from "../../hooks/useQuery";
import RecordDrawer from "../../components/RecordDrawer";
import RecordCustomAction from "../../components/RecordCustomAction";
import { RecordsetFilters } from "../../types/core";


const Container = styled.div`
  overflow-y: scroll;

  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }

  .stat-card {
    width: 240px;
    text-align: center;
  }

  .ant-select-selector {
    cursor: pointer !important;
    margin-left: -12px !important;
    margin-right: 12px !important;
  }

  .editable-cell-value-wrap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    :hover {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
`;

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <DefaultTabBar {...props} className="sticky-tab-bar sidento" />
);

const depositsBadgeStatuses: { [status: string]: PresetStatusColorType } = {
  pending: 'processing',
  posted: 'success',
  uncollectible: 'warning',
  void: 'warning',
  overdue: 'error',
}

export interface CheckpointAccountBalance {
  account_id: number;
  account_object_id: number;
  account_object_type: string;
  initial: { pending_balance: any; },
  current: { pending_balance: any; },
  account_name: string;
}

function MonthlyFinancials() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { query, handleFilter } = useQuery();
  const [checkpoint, setCheckpoint] = useState<ILedgerCheckpoint>();
  const [updatingCheckpoint, setUpdatingCheckpoint] = useState(false);
  const [contributions, setContributions] = useState<any>([]);
  const [balances, setBalances] = useState<CheckpointAccountBalance[]>([]);
  const [currentTab, setCurrentTab] = useState<string>();
  const [form] = Form.useForm();
  const [transactionModels, setTransactionModels] = useState<ITransactionModel[]>([]);

  const changeStatus = async (status: 'open' | 'closed') => {
    if (!!checkpoint) {
      setUpdatingCheckpoint(true);
      await ancanaApi.patch(`ledger-checkpoints/${id}/`, { status });
      setCheckpoint({ ...checkpoint, status });
      setUpdatingCheckpoint(false);
    }
  }

  const onEdit = async (e: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      Modal.confirm({
        title: `Add owner to this month's financials`,
        content: (
          <BrowserRouter>
            <Form
              className="pt-md"
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={async (data) => {
                const payload = { owners: [...(checkpoint?.owners.map(ow => ow.id) || []), Number(data.owner)] };
                await ancanaApi.patch(`ledger-checkpoints/${id}/?expand=ledger,owners.owner,owners.contribution_account`, payload);
                window.location.reload();
              }}
              onFinishFailed={() => {
              }}
              autoComplete="off"
            >
              <RecordSelect
                disabledKeys={checkpoint?.owners.map(ow => ow.id.toString())}
                extraFilterQuery={`&unit=${checkpoint?.ledger.object_id}`}
                recordModel={OwnerModel}
              />
            </Form>
          </BrowserRouter>
        ),
        okText: "Add",
        icon: null,
        onOk: (close: any) => form.submit()
      });
    }
    if (action === 'remove') {
      Modal.confirm({
        title: `Remove ${checkpoint?.owners.find(ow => ow.owner.email === e)?.owner.first_name} from this month?`,
        okText: "Remove",
        onOk: async () => {
          const payload = { owners: checkpoint?.owners.filter(ow => ow.owner.email !== e).map(ow => ow.id) };
          await ancanaApi.patch(`ledger-checkpoints/${id}/?expand=ledger,owners.owner,owners.contribution_account`, payload);
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    ancanaApi.get(
      `ledger-checkpoints/${id}` +
      '?expand=ledger,owners.owner,owners.contribution_account,owners.cash_account,owners.expenses_account,owners.unit.fractions' +
      '&fields=*,owners.*,owners.unit.fractions'
    ).then(res => {
      setCheckpoint(res.data);
    });
    ancanaApi.get(`ledger-checkpoints/${id}/owner-contributions`).then(res => {
      setContributions(res.data);
    });
    ancanaApi.get(`ledger-checkpoints/${id}/account-balances`).then(res => {
      setBalances(res.data);
    })
  }, [id]);

  useEffect(() => {
    const tabKey = query.get('tab');
    setCurrentTab(tabKey !== null ? tabKey : undefined);
    ancanaApi.get('transaction-models/?page_size=20'+TransactionModelModel.tableQuery).then(res => {
      setTransactionModels(res.data.results);
    });
  }, []);

  return (
    <Container className="anti-contento">
      <div className="contento" style={{ paddingBottom: 8 }}>
        <PageHeader
          onBack={() => navigate('/monthly-financials')}
          title={checkpoint?.ledger.name}
          subTitle={<>
            {!!checkpoint && (
              <RecordSelect
                defaultValue={checkpoint.id.toString()}
                extraFilterQuery={`&ledger=${checkpoint?.ledger.id}`}
                recordModel={LedgerCheckpointModel}
                bordered={false}
                labelMapper={r => {
                  const momentStart = moment().set('month', Number(r?.month) - 1).set('year', Number(r?.year)).startOf('month');
                  const momentEnd = moment().set('month', Number(r?.month) - 1).set('year', Number(r?.year)).endOf('month');
                  // return `From ${momentStart.format('MMM Do')}, to ${momentEnd.format('MMM Do YYYY')}`
                  return momentStart.format('MMMM YYYY')
                }}
                detailSwitcher={query.get('tab') ? '?tab=' + query.get('tab') : true}
              />
            )}
          </>}
          extra={checkpoint ? [
            // <Button key="2" icon={<DownloadOutlined />}>Export Statement</Button>,
            <RecordTable
              recordModel={ExpenseConceptUnitSettingsModel}
              recordsetFilters={{ unit: checkpoint.ledger.object_id }}
              modalView
            />,
            <Dropdown
              disabled={updatingCheckpoint}
              overlay={() => (
                <Menu>
                  {checkpoint?.status === 'open' ? (
                    <Menu.Item onClick={() => changeStatus('closed')}>Close</Menu.Item>
                  ) : (
                    <Menu.Item onClick={() => changeStatus('open')}>Open</Menu.Item>
                  )}
                </Menu>
              )}
              key="1"
              trigger={["click"]}
            >
              <Button className="capitalize" type="primary" loading={updatingCheckpoint}>
                <Space>{checkpoint?.status} <DownOutlined /></Space>
              </Button>
            </Dropdown>,
          ] : []}
        />
      </div>

      <Tabs
        renderTabBar={renderTabBar}
        defaultActiveKey="house"
        activeKey={currentTab}
        className="mt-sm white-bg mb-xl"
        type="editable-card"
        tabBarGutter={4}
        onEdit={onEdit}
        onTabClick={(key) => {
          handleFilter('tab', key, true);
          setCurrentTab(key);
        }}
      >
        <Tabs.TabPane
          closable={false}
          className="sidento pt-sm pb-xl"
          tab={<div><HomeOutlined />{checkpoint?.ledger.name}</div>} key="house">
          {!!checkpoint && (
            <div>
              <div className="disp-flex h-stack gap-sm h-center mb-md">
                <Card size="small" className="stat-card">
                  <Statistic
                    title="Reserve Fund"
                    value="123"
                    precision={2}
                    className="flx1"
                    prefix="$"
                  />
                  <>
                    {transactionModels.filter(tm => tm.code === 'use_reserve').map(tm => (
                      <RecordCustomAction<ITransactionModel>
                        recordModel={TransactionModelModel}
                        record={tm}
                        doRecordAction={null}
                        recordsetFilters={{
                          ledger_obj_id: checkpoint.ledger.object_id.toString(),
                          effective_at: `${checkpoint.year}-${checkpoint.month.toString().replace(/\b\d\b/g, '0$&')}-01`,
                          status: 'posted'
                        }}
                      />
                    ))}
                  </>
                </Card>
              </div>

              <div className="disp-flex h-stack-strict justify-between mb-sm">
                <div className="fs-3-25 fw-600 mb-sm">Deposits</div>
                <RecordTable
                  recordModel={InvoiceModel}
                  recordsetFilters={{
                    unit: checkpoint.ledger.object_id,
                    billing_period: `${checkpoint.year}-${checkpoint.month.toString().padStart(2, '0')}-01`
                  }}
                  modalView
                />
              </div>
              <Table
                dataSource={contributions.map((c: any, idx: number) => {
                  const bal = contributions.slice(0, idx + 1).reduce((acc: number, c: any) => (c.status === 'posted' ? Number(c.amount) : 0) + acc, 0);
                  return { ...c, balance: bal };
                })}
                columns={[
                  { title: 'Trans', dataIndex: 'id', width: 70 },
                  { title: 'Owner', dataIndex: 'owner' },
                  { title: 'Amount', dataIndex: 'amount', render: a => `$${a.toLocaleString()}` },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    render: s => <Badge status={depositsBadgeStatuses[s]} text={s === 'posted' ? 'paid' : s} />
                  },
                  { title: 'Balance', dataIndex: 'balance', render: b => `$${b.toLocaleString()}` },
                ]}
                size="small"
                pagination={false}
              />
              <br /><br />
              <ExpenseTransactionsEditor
                disabled={checkpoint.status === 'closed'}
                account="Expenses"
                checkpoint={checkpoint}
                initialBalance={contributions.reduce((acc: number, c: any) => Number(c.amount) + acc, 0)}
              />
            </div>
          )}
        </Tabs.TabPane>

        {checkpoint?.owners.map(owner => (
          <Tabs.TabPane
            className="sidento pt-sm pb-xl"
            tab={<div><UserOutlined />{owner.owner.first_name}</div>}
            key={`owner${owner.id}`}
          >
            {!!checkpoint && (
              <OwnerExpenses
                accountBalances={balances.filter(bal => bal.account_object_type === 'unitowner' && bal.account_object_id === owner.id)}
                unitOwnership={owner}
                checkpoint={checkpoint}
              />
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Container>
  )
}

export default MonthlyFinancials;
