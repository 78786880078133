import { Form, Input, InputNumber, Switch, Upload, Button, Select, Divider, DatePicker } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { PlusOutlined, UploadOutlined, MinusCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CustomFieldProps, FieldModels, Identifiable, RecordModel, RecordsetFilters } from "../types/core";
import moment from "moment/moment";
import YearSelect from "./fields/YearSelect";
import RelatedFieldSelect from "./fields/RelatedFieldSelect";
import SlugInput from "./SlugInput";
import { IContentType } from "../types/models";
import GenericRecordSelect from "./fields/GenericRecordSelect";

const UploadComponent = (props: any) => (
  <Upload
    {...props}
    beforeUpload={() => false}
  >
    <Button icon={<UploadOutlined />}>Upload file</Button>
  </Upload>
)

const MonthYearSelect = (props: any) => {
  useEffect(() => {
    if (props.formRef && props.formRef.current) {
      props.formRef.current.setFieldsValue({ month: moment().month() + 1 });
      props.formRef.current.setFieldsValue({ year: moment().year() });
    }
  }, [props.formRef]);

  return (
    <div className="mb-ml">
      <div className="mb-sm">Month</div>
      <DatePicker.MonthPicker
        defaultValue={moment()}
        onChange={(date: any) => {
          props.formRef.current.setFieldsValue({ month: date.month() + 1 });
          props.formRef.current.setFieldsValue({ year: date.year() });
        }}
      />
      <Form.Item
        noStyle
        key="month"
        name="month"
        style={props.formItemStyle}
      >
        <input type="hidden" name="month" />
      </Form.Item>
      <Form.Item
        noStyle
        key="year"
        name="year"
        style={props.formItemStyle}
      >
        <input type="hidden" name="year" />
      </Form.Item>
    </div>
  )
}

const InputComponents: {[inputName: string]: any} = {
  // date: (props: any) => <DatePicker {...props} format="LL" />,
  number: InputNumber,
  integer: InputNumber,
  decimal: InputNumber,
  password: Input.Password,
  textarea: (props: any) => <Input.TextArea {...props} autoSize={{ minRows: 3, maxRows: 10 }} size={{ resize: 'vertical' }} />,
  checkbox: (props: any) => <Switch {...props} style={{}} />,
  string: Input,
  // 'file upload': (props: any) => <input type="file" />,
  'file upload': UploadComponent,
  choice: Select,
  slug: SlugInput,
  boolean: (props: any) => <Switch {...props} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} style={{}} />,
}

interface RecordFieldsMapperProps<RecordType> {
  fieldDefinitions: any;
  contentType?: IContentType;
  customCreateFields?: { name: string, component: ReactNode | ((props: CustomFieldProps<RecordType>) => ReactNode) }[];
  formItemStyle?: object;
  setFieldsValue?: any;
  prePath?: string[];
  recordsetFilters?: RecordsetFilters;
  recordModel: RecordModel<RecordType>;
  formRef?: any;
  recordInstance?: RecordType;
}

const getFieldComponent = (fieldInfo: any) => {
  let fieldType = fieldInfo.type;
  if (fieldInfo.type === "string" && (!fieldInfo.max_length || fieldInfo.max_length > 255 )) {
    fieldType = 'textarea';
  }
  return InputComponents[fieldType] || Input;
}

const getFile = (e: any) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.file;
};


const getRand = () => (Math.random() + 1).toString(36).substring(7);

function RecordFieldsMapper<RecordType extends Identifiable>(props: RecordFieldsMapperProps<RecordType>) {
  const { fieldDefinitions, customCreateFields = [], prePath = [], contentType } = props;
  const [arrayNestedObjs, setArrayNesteObjs] = useState([getRand()]);
  const monthYearSelect = ['month', 'year'].every(sf => Object.entries(fieldDefinitions).map((kv: any) => kv[0]).includes(sf));
  const hasContentTypeField = Object.entries(fieldDefinitions).some(([fieldName, _]: any) => fieldName === 'content_type');
  const hasObjectIdField = Object.entries(fieldDefinitions).some(([fieldName, _]: any) => fieldName === 'object_id');

  return (
    <>
      {monthYearSelect && (
        <MonthYearSelect {...props} />
      )}
      {Object.entries(fieldDefinitions)
        .filter(([_, fieldSchema]: any) => !fieldSchema.read_only)
        .filter(([fieldName, _]: any) => !(monthYearSelect && ['month', 'year'].includes(fieldName))) // exclude month and year fields when monthYearSelect is used
        .filter(([fieldName, _]: any) => !(hasContentTypeField && fieldName === 'object_id'))
        .sort((a: any) => !customCreateFields.find(cf => cf.name === a[0]) ? 1 : 0)
        .map((([fieldName, fieldSchema]: any) => {
          if (fieldName === 'year') {
            return (<YearSelect {...props} key={fieldName} rules={[{ required: fieldSchema.required }]} />);
          }

          const customField: any = customCreateFields.find(cf => cf.name === fieldName);
          if (customField) {
            if (typeof customField['component'] === 'function') {
              return customField['component']({
                recordsetFilters: props.recordsetFilters ? props.recordsetFilters[fieldName] : undefined,
                formName: prePath.length > 0 ? [...prePath, fieldName] : fieldName,
                formRef: props.formRef,
                fieldSchema: fieldSchema,
                record: props.recordInstance
              });
            } else {
              return customField['component'];
            }
          }

          const InputComponent = getFieldComponent(fieldSchema);

          let innerNode = null;
          if (fieldSchema.type === 'choice') {
            innerNode = (
              <>
                {fieldSchema.choices.map((choice: any) => <Select.Option value={choice.value}>{choice.display_name}</Select.Option>)}
              </>
            )
          }

          const customFormatter = (fieldName.includes('amount') || fieldName.includes('balance')) ? (value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : undefined;

          if (fieldSchema.type === 'nested object') {
            return (
              <RecordFieldsMapper
                recordModel={props.recordModel}
                customCreateFields={customCreateFields}
                fieldDefinitions={fieldSchema.children}
                setFieldsValue={props.setFieldsValue}
                // recordsetFilters={!!props.recordsetFilters ? props.recordsetFilters[fieldName] : undefined}
                prePath={[...prePath, fieldName]}
                contentType={contentType}
              />
            );
          }

          if (fieldSchema.type === 'field' && fieldName === 'content_type' && hasObjectIdField) {
            return (
              <GenericRecordSelect formRef={props.formRef} {...fieldSchema} />
            )
          }

          if (fieldSchema.type === 'field' && !!fieldSchema.child) {
            let filters: RecordsetFilters;
            if (props.recordsetFilters && typeof props.recordsetFilters[fieldName] === 'object') {
              filters = (props.recordsetFilters[fieldName] as any);
            }
            return (
              <>
                <Divider orientation="left" orientationMargin="0">
                  {fieldSchema.label}
                </Divider>
                {arrayNestedObjs.map((uid, idx) => (
                  <div style={{ display: 'flex', gap: 0, position: 'relative' }}>
                    <RecordFieldsMapper
                      recordModel={props.recordModel}
                      customCreateFields={customCreateFields}
                      fieldDefinitions={fieldSchema.child.children}
                      setFieldsValue={props.setFieldsValue}
                      recordsetFilters={(filters as any)}
                      prePath={[...prePath, fieldName, idx]}
                      contentType={contentType}
                    />
                    {arrayNestedObjs.length -1 === idx && (
                      <MinusCircleOutlined
                        style={{ position: 'absolute', right: -18, bottom: '40%' }}
                        onClick={() => setArrayNesteObjs(arrayNestedObjs.filter(nobj => nobj !== uid))}
                      />
                    )}
                  </div>
                ))}
                <Button
                  type="dashed"
                  block
                  icon={<PlusOutlined />}
                  onClick={() => setArrayNesteObjs([...arrayNestedObjs, getRand()])}
                >
                  New {fieldName} item
                </Button>
              </>
            );
          }

          if (fieldSchema.type === 'field' && !fieldSchema.child) {
            return (
              <RelatedFieldSelect<RecordType>
                fieldName={fieldName}
                fieldDefinition={fieldSchema}
                formRef={props.formRef}
                recordModel={props.recordModel}
                recordsetFilters={props.recordsetFilters}
              />
            );
          }

          return (
            <Form.Item
              key={fieldName}
              name={prePath.length > 0 ? [...prePath, fieldName] : fieldName}
              label={fieldSchema.label}
              style={props.formItemStyle}
              className="flx1 position-relative"
              rules={[{ required: fieldSchema.required }]}
              valuePropName={fieldSchema.type === 'boolean' ? 'checked' : 'value'}
              getValueFromEvent={fieldSchema.type === 'file upload' ? getFile : undefined}
            >
              <InputComponent
                type={fieldSchema.type === 'datetime' ? 'datetime-local' : fieldSchema.type}
                formatter={customFormatter}
                style={{ width: '100%' }}
                placeholder={fieldSchema.help_text ? fieldSchema.help_text : ''}
                maxLength={fieldSchema.max_length}
                // showCount={true}
                showCount={!!fieldSchema.max_length}
                setValue={(val: string) => props.formRef.current.setFieldsValue({ [fieldName]: val })}
              >
                {innerNode}
              </InputComponent>
            </Form.Item>
          )
        }))
      }
    </>
  )
}

export default RecordFieldsMapper;
