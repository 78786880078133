import styled from 'styled-components';
import { ReactNode, useMemo, useState } from "react";
import moment, { Moment } from "moment/moment";
import { Button, Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";


const CalHeader = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  width: 100%;

  .date {
    font-size: 18px;
    font-weight: 500;
  }
`;

const DateCell = styled.div<any>`
  height: 100%;
  background-color: ${({ isSelected }) => isSelected ? '#f3d5ba' : 'white'};
  padding: 8px 12px;
  outline: 1px solid #f0f0f0;
  user-select: none;

  .day-label {
    font-size: 12px;
    font-weight: 300;
  }
`;

interface PropsType {
  selectable?: boolean;
  filters?: ReactNode;
  dateCellContentRender?: (date: Moment) => ReactNode;
  onlyMonthDays?: boolean;
}

function useCustomCalendar(props?: PropsType) {
  const [currentMonthDate, setCurrentMonthDate] = useState<any>(moment());
  const {
    selectable = false,
    dateCellContentRender = (date: Moment) => <></>,
    onlyMonthDays = false
  } = props || {};
  const [dateStart, setDateStart] = useState<Moment>();
  const [dateEnd, setDateEnd] = useState<Moment>();
  const selectedDateRange: Moment[] = useMemo(() => {
    if (!dateStart) return [];
    if (!dateEnd) return [dateStart];
    const datesDiff = dateStart.diff(dateEnd);
    if (datesDiff === 0) return [dateStart];
    if (datesDiff < 0) return [dateStart, dateEnd];
    if (datesDiff > 0) return [dateEnd, dateStart];
    return []
  }, [dateStart, dateEnd]);

  const headerRender = (object: { value: Moment, type: string, onChange: any, onTypeChange: any }) => {
    const { value, type, onChange } = object;
    const year = value.year();
    const options = [];
    for (let i = year - 3; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      );
    }

    return (
      <CalHeader>
        <div className="full-width center justify-between pb-md">
          <div className="flx1">
            {props?.filters}
          </div>
          <div className="date h-center flx1">
            {value.format("MMMM YYYY")}
          </div>
          <div className="flx1" style={{ justifyContent: 'flex-end' }}>
            <Select
              dropdownMatchSelectWidth={false}
              className="mr-md"
              value={year}
              onChange={(newYear: number) => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
              style={{ width: 100 }}
            >
              {options}
            </Select>

            <Button onClick={() => onChange(value.clone().startOf('month').subtract(1, 'days'))}>
              <LeftOutlined />
            </Button>
            <Button onClick={() => onChange(value.clone().endOf('month').add(1, 'days'))}>
              <RightOutlined />
            </Button>
          </div>
        </div>
      </CalHeader>
    )
  }

  const dateCellFullRender = (value: Moment) => {
    const checkIsSelected = () => {
      switch (selectedDateRange.length) {
        case 0:
          return false;
        case 1:
          return value.isSame(selectedDateRange[0]);
        case 2:
          return value.isBetween(selectedDateRange[0], selectedDateRange[1], undefined,  "[]");
        default:
          return false;
      }
    }

    if (onlyMonthDays && value.month() !== currentMonthDate.month()) return (
      <div style={{ width: '100%', height: '100%', background: '#fafafa', outline: '1px solid #f0f0f0' }} />
    );

    return (
      <DateCell
        isSelected={checkIsSelected()}
        onSelect={() => console.log(value)}
        onMouseOver={(e: any) => {
          if (selectable && e.buttons === 1 && !!dateStart ) {
            setDateEnd(value);
          }
        }}
        onMouseDown={(e: any) => {
          if (selectable && e.buttons === 1) {
            setDateStart(value);
            setDateEnd(undefined);
          }
        }}
      >
        <div className="day-label">
          {value.date().toString()}
        </div>

        {dateCellContentRender(value)}
      </DateCell>
    )
  }

  const onPanelChange = (m: any) => setCurrentMonthDate(m);

  return {
    selectedDateRange,
    headerRender,
    dateCellFullRender,
    setDateStart,
    setDateEnd,
    onPanelChange,
    currentMonthDate
  }
}

export default useCustomCalendar;
