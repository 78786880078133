import { RecordModel } from "../core";
import RecordSelect from "../../components/fields/RecordSelect";
import GenericRecordSelect from "../../components/fields/GenericRecordSelect";
import { UnitModel, OwnerModel } from "./housing";
import { Badge, DatePicker, Form } from "antd";
import { FileProtectOutlined, PlusOutlined, SendOutlined, BarChartOutlined } from '@ant-design/icons';
import moment from "moment/moment";
import { OwnerType } from "../coreModels";
import React from "react";
import { PresetStatusColorType } from "antd/lib/_util/colors";

export type IJournal = {
  id: number;
  owner_name: string;
  description: string;
  is_main: boolean;
  content_object: any;
}

export const JournalModel: RecordModel<IJournal> = {
  name: "Journal",
  path: "journals",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.owner_name || `Journal ${r.id}`,
}

export type IInvoice = {
  id: number;
  journal: {
    description: string;
    is_main: boolean,
    invoices: number[];
    owner_name: string;
  };
  billing_period: string;
  due_date: string;
  is_paid: boolean;
  status: string;
  total: number;
  stripe_invoice: number;
  invoiceline_set: number[];
  currency: any;
}

export type IEntry = {
  id: number;
  name: string;
  description: string;
  direction: "debit" | "credit";
}

export type ITransaction = {
  id: number;
  ledger: number;
  name: string;
  entries: IEntry[];
  posted_at: string;
  effective_at: string;
}

export type ILedger = {
  id: number;
  name: string;
  description: string;
  transactions: ITransaction[];
  currency: string;
  object_id: number;
  content_type: number;
  unit_cash_account: number | null;
  unit_expenses_account: number | null;
  unit_revenue_account: number | null;
};

export type IAccount = {
  id: number;
  name: string;
  description: string;
  normal_balance: "debit_normal" | "credit_normal";
  ledger: number;
  owner_name: string;
}

export type IExpenseConcept = {
  id: number;
  code: string;
  name: string;
  name_es?: string;
}

export type IExpenseConceptUnitSettings = {
  id: number;
  concept: IExpenseConcept;
  unit: { id: number; name: string };
  fixed_amount: number;
  periodicity: "monthly" | "yearly" | "none";
  is_mx_invoiced: boolean;
}

export type ILedgerCheckpoint = {
  id: number;
  ledger: ILedger;
  month: string;
  year: string;
  admin_fee: string;
  status: string;
  owners: OwnerType[];
}


const invoiceBadgeStatuses: {[status: string]: PresetStatusColorType} = {
  draft: 'default',
  open: 'processing',
  paid: 'success',
  uncollectible: 'warning',
  void: 'warning',
  overdue: 'error',
}

export const InvoiceModel: RecordModel<IInvoice> = {
  name: "Invoice",
  path: "journal-invoices",
  selectQuery: "",
  tableQuery: "&expand=journal,currency",
  toString(r): string {
    return r.journal.owner_name;
  },
  icon: <FileProtectOutlined />,
  customTableMappings: {
    stripe_invoice: { omit: true },
    "is_paid": { omit: true },
    currency: { omit: true },
    total: { render: r => `$${(r.total/100).toLocaleString()} ${r.currency.iso}`, width: 150 },
    status: { render: r => <Badge status={invoiceBadgeStatuses[r.status]} text={r.status} />, width: 80 },
    journal: { path: ['journal', 'owner_name'], title: 'Owner' },
  },
  customCreateFields: [
    { name: 'stripe_invoice', component: null },
  ],
  defaultValues: {
    due_date: moment().add(7, 'days').format("YYYY-MM-DD"),
  },
  detailAction: '/invoices',
  customAction: {
    name: 'Send',
    path: 'advance',
    isDetail: true,
    icon: <SendOutlined />,
    disabled: r => r.status !== 'draft'
  }
}

export const LedgerModel: RecordModel<ILedger> = {
  name: "Ledger",
  path: "ledgers",
  selectQuery: "&fields=id,name",
  tableQuery: "",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  detailLinkField: 'name',
  customCreateFields: [
    { name: 'object_id', component: null },
    { name: 'content_type', component: (props: any) => <GenericRecordSelect {...props} /> },
  ]
}

export const AccountModel: RecordModel<IAccount> = {
  name: "Account",
  path: "ledger-accounts",
  selectQuery: "&fields=id,name,normal_balance,owner_name",
  tableQuery: "&ordering=id",
  toString(r): string {
    return `${r.normal_balance === 'debit_normal' ? "dn:" : "cn:"} ${r.owner_name} ${r.name}`;
  },
  customCreateFields: [
    { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'object_id', component: null },
    { name: 'content_type', component: (props: any) => <GenericRecordSelect required={false} {...props} /> },
  ],
  customTableMappings: {
    normal_balance: { omit: true },
  }
}

export const TransactionModel: RecordModel<ITransaction> = {
  name: "Transaction",
  path: "ledger-transactions",
  selectQuery: "&fields=id,name",
  tableQuery: "&expand=expense_concept",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  customCreateFields: [
    { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'account', component: (props: any) => <RecordSelect {...props} recordModel={AccountModel} allowManage /> },
    { name: 'concept', component: (props: any) => <RecordSelect {...props} required={false} recordModel={ConceptModel} /> },
  ]
}

export const ContribTransactionModel: RecordModel<ITransaction> = {
  name: "Deposit",
  path: "ledger-transactions",
  selectQuery: "&fields=id,name",
  tableQuery: "&expand=expense_concept&account_name=Owner+Contributions",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  customCreateFields: [
    // { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'account', component: (props: any) => <RecordSelect {...props} recordModel={AccountModel} allowManage /> },
  ],
  customTableMappings: {
    entries: { omit: true },
    // concept: { omit: true },
    name: { render: (r) => 'awdawdaw' }
  }
}

export const WalletTransactionModel: RecordModel<ITransaction> = {
  name: "Deposit",
  path: "ledger-transactions",
  selectQuery: "&fields=id,name",
  tableQuery: "&expand=expense_concept&account_name=Owner+Contributions",
  toString(r): string {
    return r.name ? r.name : r.toString();
  },
  customCreateFields: [
    // { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'account', component: (props: any) => <RecordSelect {...props} recordModel={AccountModel} allowManage /> },
  ],
  customTableMappings: {
    entries: { omit: true },
    // concept: { omit: true },
    name: { render: (r) => 'awdawdaw' }
  }
}

export const ConceptModel: RecordModel<IExpenseConcept> = {
  name: "Expense Concept",
  namePlural: "Expense Concepts",
  path: "ledger-concepts",
  selectQuery: "",
  tableQuery: "",
  toString(r): string {
    return `${r?.code}: ${r?.name}`;
  },
  customTableMappings: {
    id: { path: ['id'] }
  }
}

export const ExpenseConceptUnitSettingsModel: RecordModel<IExpenseConceptUnitSettings> = {
  name: "Concept Default",
  path: "concept-unit-defaults",
  selectQuery: "&expand=unit,concept",
  tableQuery: "&expand=unit,concept",
  toString: (r) => `${r.concept.name} - ${r.unit.name}`,
  customCreateFields: [
    { name: 'unit', component: (props: any) => <RecordSelect {...props} recordModel={UnitModel} /> },
    { name: 'concept', component: (props: any) => <RecordSelect {...props} recordModel={ConceptModel} allowManage /> },
  ],
  // icon: <PlusOutlined />,
  customTableMappings: {
    'unit': { path: ['unit', 'name'] },
    'concept': { render: (r: any) => `${r.concept.code}: ${r.concept.name}` },
  }
}

export const LedgerCheckpointModel: RecordModel<ILedgerCheckpoint> = {
  name: "House Monthly Financial",
  path: "ledger-checkpoints",
  selectQuery: "&fields=id,month,year,ledger.name&expand=ledger",
  tableQuery: "&expand=ledger",
  toString: (r) => `${r.month}-${r.year} ${r.ledger.name}`,
  customCreateFields: [
    { name: 'ledger', component: (props: any) => <RecordSelect {...props} recordModel={LedgerModel} /> },
    { name: 'owners', component: null }
  ],
  detailAction: true,
  customTableMappings: {
    ledger: { title: 'Name', render: r => <span>{r.ledger.name} — {moment().month(Number(r.month) - 1).format('MMMM')} {r.year}</span> },
    month: { omit: true },
    year: { omit: true },
    owners: { omit: true }
  },
  icon: <BarChartOutlined />,
  tableFilters: [
    { selectModel: LedgerModel, /*extraFilterQuery: "&has_no_owner=false"*/ },
    {
      component: ({ query, handleFilter }: any) => (
        <DatePicker.MonthPicker
          defaultValue={(!!query.get('month') && !!query.get('year')) ? moment(`${query.get('year')}-${query.get('month')}`, "YYYY-M") : undefined}
          onChange={v => {
            handleFilter('month', v ? Number(v.get('month')) + 1 : '');
            handleFilter('year', v ? v.get('year') : '');
          }}
          format="MMM YYYY"
          allowClear={true}
        />
      )
    },
  ]
}

export interface IUsagePattern {
  id: number;
  created_at: string;
  modified_at: string;
  name: string;
  frequency: "Ad Hoc" | "Monthly" | "Bimonthly";
}

export const UsagePatternModel: RecordModel<IUsagePattern> = {
  name: "Usage Pattern",
  path: "usage-patterns",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.name,
}
