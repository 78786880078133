import React, { useEffect, useMemo, useRef, useState } from "react";
import { Select, Form, Divider, Space, Button, Modal } from "antd";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useQuery from "../../hooks/useQuery";
import { Identifiable, RecordSelectProps } from "../../types/core";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import RecordFieldsMapper from "../RecordFieldsMapper";
import RecordDrawer from "../RecordDrawer";
import RecordTable from "../RecordTable";


function RecordSelect<RecordType extends Identifiable>(props: RecordSelectProps<RecordType>) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    recordModel,
    extraFilterQuery = !!props.recordsetFilters ? '&'+Object.entries(props.recordsetFilters).map(([k,v]) => `${k}=${v}`).join('&') : '',
    customCreateFields = [],
    onChange = () => {},
    required = true,
    detailSwitcher = false,
    fieldSchema
  } = props;
  const filter = useMemo(() => {
    if (props.filter) {
      return typeof props.filter === 'string' ? props.filter : recordModel.name.toLowerCase()
    }
    return null
  }, [props.filter, recordModel.name])
  const { query, handleFilter } = useQuery();
  const {
    // handleChange,
    isLoading,
    recordList,
    createRecord,
    recordFields,
    firstFetch,
    contentType
  } = usePaginatedRecordList<RecordType>({
    // query,
    recordPath: recordModel.path,
    extraQueryAppend: recordModel.selectQuery + extraFilterQuery,
    apiService: props.recordModel.apiService || 'admin',
    pageSize: '250',
    initialAutoFetch: true,
    initialOptionsFetch: true,
    onInitialAutoFetch: records => {
      if (!query.get(filter || '') && props.autoSelectFirst) {
        const firstElementId = (records[0] as any)[props.valueKey || 'id'] || '';
        handleFilter(filter || '', firstElementId.toString());
      }
      if(props.onInitialFetch) props.onInitialFetch(records);
    }
  });
  const [form] = Form.useForm();
  const [selectOpen, setSelectOpen] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [creatingRecord, setCreatingRecord] = useState(false);
  const [recordTalbeModalVisible, setRecordTableModalVisible] = useState(false);
  const recordName = recordModel.name.toLowerCase();
  const flatFormName = !!props.formName ? (typeof props.formName === 'string' ? props.formName : props.formName[0]) : undefined;
  const fieldName = flatFormName || recordModel.name.replace(/\s/g , "").toLowerCase();
  const placeholder = (filter && `Filter by ${recordName}`) || (fieldSchema?.help_text) || `Select a ${recordName}`;

  const getHighlightsFromParentForm = (): string[] | number[] | undefined => {
    let highlights;
    try {
      const selectedId = Number(props.formRef?.current?.getFieldsValue()[recordModel.name.toLowerCase()]);
      highlights = selectedId ? [selectedId] : undefined;
    } catch {}
    return highlights;
  };

  useEffect(() => {
    // console.log("parentFormChanged~");
    // setParentFormValues(props.formRef?.current?.getFieldsValue());
  }, []);

  const renderDropdown = (menu: any) => {
    if (props.allowCreate) {
      if (showCreate && recordFields === undefined) {
        return <Space style={{ padding: '6px 12px' }}>Create Not Allowed
          <Button onClick={() => setShowCreate(false)}>
            Cancel
          </Button>
        </Space>
      }
      if (showCreate) {
        return (
          <>
            <div style={{ padding: '6px 12px', display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 75px)', overflowY: 'scroll' }}>
              <div className="fw-500 fs-2-66 mb-sm">New {recordModel.name}</div>
              <Form
                form={form}
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={async (data: any) => {
                  setCreatingRecord(true);
                  await createRecord(data);
                  form.resetFields();
                  setShowCreate(false);
                  setCreatingRecord(false);
                }}
              >
                <RecordFieldsMapper
                  recordModel={props.recordModel}
                  fieldDefinitions={recordFields}
                  customCreateFields={customCreateFields}
                  formItemStyle={{ marginBottom: '8px' }}
                  contentType={contentType}
                />
              </Form>
            </div>
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px', float: 'right' }}>
              <Button onClick={() => { setShowCreate(false);form.resetFields(); }}>
                Cancel
              </Button>
              <Button type="primary" onClick={() => form.submit()} loading={creatingRecord}>
                Create
              </Button>
            </Space>
          </>
        );
      } else {
        return (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space className="full-width disp-flex" style={{ padding: '0 8px 4px' }}>
              <Button type="text" onClick={() => setShowCreate(true)}>
                <PlusOutlined /> Create New {recordModel.name}
              </Button>
            </Space>
          </>
        );
      }
    }
    if (props.allowManage) {
      return (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space className="full-width disp-flex" style={{ padding: '0 8px 4px' }}>
            <Button type="text" onClick={() => { setSelectOpen(false); setRecordTableModalVisible(true); }}>
              <SettingOutlined /> Manage Records
            </Button>
          </Space>
        </>
      );
    }
    return menu;
  }

  const select = (
    <Select
      open={selectOpen}
      onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
      showSearch
      allowClear={!props.detailSwitcher}
      mode={props.mode}
      loading={!!props.isLoading || isLoading}
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      defaultValue={filter ? query.get(filter) : props.defaultValue}
      // onSearch={(value: string) => {}}
      options={recordList.map(record => {
        const value = !!props.valueMapper ? props.valueMapper(record) : (record as any)[props.valueKey || 'id']?.toString();
        return {
          value,
          label: !!props.labelMapper ? props.labelMapper(record) : recordModel.toString(record),
          disabled: !!props.disabledKeys && props.disabledKeys.includes(value),
        }
      })}
      size={props.size || 'middle'}
      // onChange={filter ? (value) => handleFilter(filter || '', value) : (value) => onChange(value, recordList)}
      onChange={(value) => {
        if (filter) {
          handleFilter(filter || '', value)
        }
        if (detailSwitcher) {
          let pathSuffix = typeof detailSwitcher == 'string' ? detailSwitcher : '';
          const path = pathname.split('/');
          let baseUrl;
          if (path.slice(-1)[0] !== '') {
            baseUrl = path.slice(0, -1).join("/");
          } else {
            baseUrl = path.slice(0, -2).join("/");
          }
          // navigate(`${baseUrl}/${value}${pathSuffix}`, { search: `?${path}` });
          window.location.href = `${baseUrl}/${value}${pathSuffix}`;
          //window.location.reload();
        }
        if (!(filter && detailSwitcher)) {
          onChange(value, recordList);
        }
      }}
      // onSelect={(value) => onSelect(value, recordList)}
      style={{ minWidth: 150 }}
      dropdownRender={(menu: any) => props.dropdownRender ? props.dropdownRender(menu) : renderDropdown(menu)}
      bordered={props.bordered}
    />
  );

  if (filter || detailSwitcher || props.selectOnly) {
    return select;
  }
  return (
    <>
      <Form.Item
        name={props.formName || fieldName}
        label={props.label || flatFormName || recordModel.name}
        initialValue={props.defaultValue || (props.mode === 'multiple' ? [] : undefined)}
        rules={[{ required: !!fieldSchema ? fieldSchema.required : required }]}
      >
        {select}
      </Form.Item>

      {props.allowDrawerCreate && (
        <RecordDrawer<RecordType>
          recordModel={recordModel}
          customCreateFields={customCreateFields}
          saveAction={(data: any) => {
            createRecord(data, (res) => {
              if (props.onCreate) props.onCreate(res.data);
            });
          }}
        />
      )}

      <Modal
        visible={recordTalbeModalVisible}
        onCancel={() => {
          setRecordTableModalVisible(false);
          firstFetch();
          setSelectOpen(true);
        }}
        footer={null}
        width={1000}
        title={(
          <div className="disp-flex h-stack-strict gap-xs center">
            <SettingOutlined color="#fff0ff" />
            <div>Manage {recordModel.name} Records</div>
          </div>
        )}
      >
        <RecordTable<RecordType>
          highlightRecords={props.highlightRecords || getHighlightsFromParentForm()}
          recordsetFilters={props.recordsetFilters}
          recordModel={props.recordModel}
        />
      </Modal>
    </>
  )
}

export default RecordSelect;
